import React from "react"
import { Card, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import nopic from "../../../assets/img/illustrations/no_contact.svg"
import moment from "moment"
import Settings from "../../../common/Settings"

const ForeignersVerificarAntecedentesListView = ({
  records,

  isLoading,
}) => {
  const { t } = useTranslation("global")
  return (
    <Col xs={12}>
      <Card className="mb-4">
        <Card.Body className="p-0 ">
          <div className="table-responsive">
            {records && records.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th></th>

                    <th>{t("Nombre")}</th>
                    <th className="text-center">{t("Recomendaciones")}</th>
                  </tr>
                </thead>
                <tbody>
                  {records &&
                    records.map((record: any, index: number) => {
                      console.log(record)
                      return (
                        <tr key={index}>
                          <td>
                            {record.idFotoFrente && (
                              <img
                                alt=""
                                loading="lazy"
                                className=" img-thumbnail my-2"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                                onError={(e: any) => {
                                  debugger
                                  if (
                                    e.target.src !==
                                    "https://placehold.co/600x400"
                                  ) {
                                    e.target.src =
                                      "https://placehold.co/600x400"
                                  }
                                }}
                                id={"image" + index}
                                src={
                                  record.idFotoFrente.includes("base64")
                                    ? record.idFotoFrente
                                    : record.idFotoFrente.includes("/")
                                    ? `${Settings.BasePath}/${record.idFotoFrente}`
                                    : `${Settings.BasePath}/files/${record.idFotoFrente}`
                                }
                              />
                            )}
                          </td>

                          <td>
                            <b className="me-2">{t("Name")}:</b>
                            {`${record.nombre ?? ""} ${record.apellidos ?? ""}`}
                            <br />
                            <b className="me-2">Cédula</b>
                            {record.cedula}
                            <br />
                            <b className="me-2">{t("Fecha de Nacimiento")}</b>
                            {record.fechaNacimiento &&
                              moment(
                                record.fechaNacimiento.substring(0, 10)
                              ).format("DD/MM/YYYY")}
                            <br />
                            <b className="me-2">{t("Nacionalidad")}</b>
                            {record.nacionalidad ?? "-"}
                            <br />
                            <b className="me-2">{t("Estatus Migratorio")}</b>
                            {record.estatusResidencia ?? "-"}
                            <br />
                            <b className="me-2">{t("Alias")}</b>
                            {record.alias1}
                          </td>

                          <td className="text-center">
                            {/* {record.fichas === 0 && (
                              <span
                                className="badge bg-success"
                                title={"No antencedentes judiciales"}
                              >
                                <i className="fa fa-check me-2"></i>
                                No antencedentes judiciales
                              </span>
                            )}
                            {record.fichas > 1 && (
                              <span
                                className="badge bg-danger"
                                title={
                                  "Solicitar certificado de no antecedentes judiciales"
                                }
                              >
                                <i className="fa fa-exclamation-triangle me-2"></i>
                                Solicitar certificado de no antecedentes
                                judiciales
                              </span>
                            )} */}
                            {record.observaciones &&
                              record.observaciones.length > 0 && (
                                <span
                                  className="badge bg-danger"
                                  title={
                                    "Solicitar certificado de no antecedentes judiciales"
                                  }
                                >
                                  <i className="fa fa-exclamation-triangle me-2"></i>
                                  Solicitar certificado de no antecedentes
                                  judiciales
                                </span>
                              )}
                            <br />
                            <div
                              className="badge bg-warning  text-wrap"
                              title={"No casos laborales"}
                            >
                              <i className="fa fa-check"></i>
                              <span className="ms-2">No casos laborales</span>
                            </div>
                          </td>
                        </tr>
                      )
                    })}

                  {records.length === 0 ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {t("No Results")}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            ) : (
              <div className="text-center py-2">
                <h3>{t("No records found")}</h3>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default ForeignersVerificarAntecedentesListView
