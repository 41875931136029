import React from "react"
import { Card, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Settings from "../../common/Settings"

const HomeComponentListView = ({ records, toggleAddEdit, deleteRecord }) => {
  const { t } = useTranslation("global")
  return (
    <Col xs={12}>
      <Card className="mb-4">
        <Card.Body className="p-0 ">
          <div className="table-responsive">
            {records && records.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {records &&
                    records.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1} </td>
                          <td>
                            {item.identifierPicture && (
                              <img
                                alt=""
                                className="img-fluid img-thumbnail my-2"
                                style={{
                                  minHeight: "100px",
                                  minWidth: "100px",
                                  maxWidth: "200px",
                                  maxHeight: "200px",
                                  objectFit: "cover",
                                  height: "100px",
                                  width: "100px",
                                }}
                                id="image"
                                src={`${Settings.BasePath}/files/${item.identifierPicture}`}
                              />
                            )}
                          </td>
                          <td>
                            <b className="me-2">{t("Nombres")}:</b>
                            {`${item.firstName} ${item.middleName ?? ""}`}
                            <br />
                            <b className="me-2">{t("Apellidos")}:</b>
                            {`${item.lastName} ${item.secondLastName ?? ""}`}

                            <br />
                            <b className="me-2">{t("Cedula")}:</b>
                            {item.identifier}
                            <br />
                            <b className="me-2">{t("Employee Type")}:</b>
                            {item.employeeTypeName ?? "-"}
                          </td>

                          <td className="text-end">
                            <div className="btn-group">
                              <button
                                className="btn btn-sm btn-primary  d-xs-none"
                                type="button"
                                onClick={() => {
                                  toggleAddEdit(item, true)
                                }}
                              >
                                <i className="fa fa-eye" />
                              </button>
                              <button
                                className="btn btn-sm btn-primary  d-xs-none"
                                type="button"
                                onClick={() => toggleAddEdit(item)}
                              >
                                <i className="fa fa-edit" />
                              </button>
                              <button
                                className="btn btn-sm btn-primary  d-xs-none"
                                type="button"
                                onClick={() => {
                                  deleteRecord(item)
                                }}
                              >
                                <i className="fa fa-trash text-danger" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            ) : (
              <div className="text-center py-2">
                <h3>{t("No records found")}</h3>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default HomeComponentListView
