import React, { useEffect, useState } from "react"
import API from "../../common/API"
import { useTranslation } from "react-i18next"
import { Search } from "semantic-ui-react"
import Settings from "../../common/Settings"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import { set } from "lodash"
const EmployeeRelativeAddEdit = (props: any) => {
  // PROPERTIES
  const [relativeTypes, setRelativeTypes] = useState(props.records ?? [])
  const [records, setRecords] = useState<any>([])
  const { t } = useTranslation("global")
  // METHODS
  const bindDataRelativeTypes = async () => {
    try {
      let requestAPI: any = await API.postAction("RelativeType/list", {
        page: 0,
        quantity: 50,
      })
      if (requestAPI.data.status === "ok") {
        setRelativeTypes(requestAPI.data.response)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const deleteRecord = async (item: any) => {
    if (item) {
      confirmAlert({
        closeOnClickOutside: false,
        message: t("Are you sure you want to delete this record?") ?? "",
        buttons: [
          {
            label: t("Yes"),
            onClick: async () => {
              await API.getAction(
                "relativeType/DeleteForEmployee?id=" + item.id
              )
              setRecords(records.filter((record: any) => record.id !== item.id))
              props.updateRelativeArray(
                records.filter((record: any) => record.id !== item.id)
              )
            },
          },
          {
            label: "No",
            onClick: () => {
              console.log("no")
            },
          },
        ],
      })
    }
  }
  const updateField = (fieldName: string, fieldValue: any, index: number) => {
    console.log(fieldName, fieldValue)
    let data: any[] = []
    records.forEach((item: any, internalIndex: number) => {
      if (internalIndex === index) {
        item[fieldName] = fieldValue
      }
      data.push(item)
    })

    setRecords(data)
    props.updateRelativeArray(data)
  }
  const bindDataRemote = async () => {
    try {
      console.log(props)
      if (props.parent && props.parent.id) {
        setRecords(props.relatives)
      } else {
        //We need to create it
        let data: any = []
        relativeTypes.forEach((item: any) => {
          data.push({
            id: null,
            relativeTypeId: item.id,
            relativeName: item.name,
            firstName: null,
            lastName: null,
            identifier: null,
            photo: null,
            deleted: null,
          })
        })

        setRecords(data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const searchCedula = async (index: number, obj: any) => {
    try {
      // setLoading(true)
      const requestAPI = await API.getActionExternal(
        Settings.BasePathForAPIPADRON + "Cedulados/Detalle?id=" + obj.identifier
      )
      let item = requestAPI.data.response[0]
      updateField("firstName", item.nombres, index)
      updateField("lastName", item.apellido1, index)
      console.log(item)
    } catch (e) {
      console.error(e)
    } finally {
      // setLoading(false)
    }
  }

  // EVENTS
  useEffect(() => {
    if (props.parent && props.parent.relatives) {
      console.log(props.parent.relatives)
      console.log("props", props.parent.relatives)
      setRecords(props.parent.relatives)
      return
    }
    bindDataRelativeTypes()
  }, [props.parent])
  useEffect(() => {
    bindDataRemote()
  }, [relativeTypes])

  useEffect(() => {
    console.log(props.record)
  }, [props.record])

  useEffect(() => {
    if (props.relatives && props.relatives.length > 0) {
      console.log("props", props.relatives)
      setRecords(props.relatives)
    }
  }, [props.relatives])
  return (
    <table className="table table-striped">
      <tbody>
        {records &&
          records.length > 0 &&
          records.map((item: any, index: number) => {
            console.log(item)
            return (
              <tr key={index}>
                <td>
                  <div className="input-group">
                    <input
                      type="text"
                      disabled={props.readOnly ?? false}
                      className="form-control"
                      name={"identifier" + index}
                      placeholder={`${t("identification")} ${
                        item.relativeName
                      }`}
                      value={item.identifier ?? ""}
                      onChange={e => {
                        updateField("identifier", e.target.value, index)
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={() => {
                          searchCedula(index, item)
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    disabled={props.readOnly ?? false}
                    className="form-control"
                    name="firstName"
                    value={item.firstName}
                    placeholder={t("firstName") ?? ""}
                    onChange={e => {
                      updateField("firstName", e.target.value, index)
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    disabled={props.readOnly ?? false}
                    className="form-control"
                    value={item.lastName}
                    name="lastName"
                    placeholder={t("lastName") ?? ""}
                    onChange={e => {
                      updateField("lastName", e.target.value, index)
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    disabled={props.readOnly ?? false}
                    className="form-control"
                    value={item.phone}
                    name="phone"
                    placeholder={t("phone") ?? ""}
                    onChange={e => {
                      updateField("phone", e.target.value, index)
                    }}
                  />
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-danger"
                    type="button"
                    onClick={() => {
                      deleteRecord(item)
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}
export default EmployeeRelativeAddEdit
