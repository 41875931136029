import SettingSectionDTO from "./SettingSectionDTO"

const SettingSetions: SettingSectionDTO[] = [
  {
    name: "administration",
    parentId: "admin",
    requiredParent: false,
    sections: [
      {
        reference: "users",
        label: "users",
      },
      {
        reference: "roles",
        label: "roles",
      },
    ],
  },

  {
    name: "Parameters",
    parentId: "admin",
    requiredParent: false,
    sections: [
      {
        reference: "countries",
        label: "Countries",
      },
      {
        reference: "earTypes",
        label: "Ear Types",
      },
      {
        reference: "eyeTypes",
        label: "Eye Types",
      },
      {
        reference: "hairType",
        label: "Hair Types",
      },
      {
        reference: "height",
        label: "Heights",
      },
      {
        reference: "weight",
        label: "Weights",
      },
      {
        reference: "skinColor",
        label: "Skin Color",
      },
      {
        reference: "physicalBuild",
        label: "Physical Build",
      },
      {
        reference: "mouthTypes",
        label: "Mouth Types",
      },
      {
        reference: "noseType",
        label: "Nose Types",
      },
      {
        reference: "expressionLine",
        label: "Expression Lines",
      },
      {
        reference: "face",
        label: "Face",
      },
      {
        reference: "nationality",
        label: "Nationality",
      },
      {
        reference: "socialGroup",
        label: "Social Group",
      },
      {
        reference: "civilStatus",
        label: "Civil Status",
      },
      {
        reference: "occupation",
        label: "Occupation",
      },
      {
        reference: "tattooPlace",
        label: "Tattoo Place",
      },
      {
        reference: "scarPlace",
        label: "Scar Place",
      },
      {
        reference: "stain",
        label: "Stains",
      },
      {
        reference: "burnPlace",
        label: "Burn Place",
      },
      {
        reference: "voiceQuality",
        label: "Voice Quality",
      },
      {
        reference: "discapacity",
        label: "Discapacity",
      },
      {
        reference: "employeeType",
        label: "Employee Type",
      },
      {
        reference: "biometricalPhotoTypes",
        label: "Biometrical Photo Types",
      },
      {
        reference: "complainCategory",
        label: "Complain Categories",
      },
      {
        reference: "complainCouldYouRecognize",
        label: "Could you recognize",
      },
      {
        reference: "typeOfLocation",
        label: "Type of location",
      },
      // {
      //   reference: "companyType",
      //   label: "Company Types",
      // },
    ],
  },
]

export default SettingSetions
