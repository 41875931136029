import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import EmployeDTO from "./EmployeDTO"
import CRUDField from "../core/genericCRUD/CRUDField"
import FieldSelector from "../core/FieldSelector"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import * as yup from "yup"
import EmployeeRelativeAddEdit from "./EmployeeRelativeAddEdit"
import EmployeePhotoTypes from "./EmployeePhotoTypes"
import yupTypeValidator from "../../common/YupTypeValidator"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import { toast } from "react-toastify"
import CRUD from "../core/genericCRUD/CRUD"
import { Base64ToFile } from "../../common/helpers"
import EmployeeBiometricalPhoto from "./EmployeeBiometricalPhoto"

const EmployeAddEdit = props => {
  // PROPERTIES
  const [record, setRecord] = useState<any>(null)
  const [recordRelatives, setRecordRelatives] = useState<any>([])
  const fields: CRUDField[] = EmployeDTO
  const { t } = useTranslation("global")
  const [formikInitialObject, setFormikInitialObject] = useState<any>({})
  const [validationsSchema, setValidationSchema] =
    useState<yup.ObjectSchema<any>>()
  const [isLoading, setIsLoading] = useState(false)
  const user = parsedUser()
  const formik = useFormik({
    initialValues: formikInitialObject,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: () => handleSubmit(),
  })
  type YupSchema =
    | yup.StringSchema
    | yup.BooleanSchema
    | yup.NumberSchema
    | yup.DateSchema
  // METHODS

  const fillFormikObject = (record: any) => {
    fields.forEach((item: CRUDField) => {
      if (item && item.name.length > 0) {
        //console.log("field name", item.name, "RecordValue ", record[item.name]);
        if (record && record[item.name]) {
          formik.setFieldValue(item.name, record[item.name])
        }
      }
    })
  }

  const prepareFormikObject = () => {
    const initialObject: { [key: string]: string } = {}
    const initialObjectValidation: { [key: string]: YupSchema } = {}

    if (fields) {
      fields.forEach((item: CRUDField) => {
        // if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.name] = item.value

        if (item.required) {
          const schema = yupTypeValidator(item)
          if (schema) {
            initialObjectValidation[item.name] = schema
          }
        }
      })
    }

    // formik.initialValues = initialObject;
    setFormikInitialObject(initialObject)
    setValidationSchema(yup.object().shape(initialObjectValidation))
  }

  const updateField = (fieldName: string, fieldValue: any) => {
    console.log(fieldName, fieldValue)
    formik.setFieldValue(fieldName, fieldValue)
  }
  const updatePhotosArray = (value: any) => {
    formik.setFieldValue("photos", value)
    console.log("photos", value)
  }
  const updateBiometricalPhotosArray = (value: any) => {
    formik.setFieldValue("biometrical", value)
    console.log("biometrical", value)
  }

  const updateRelativeArray = (value: any) => {
    formik.setFieldValue("relatives", value)
    console.log("relatives", value)
  }

  const resultadosCedula = async (value: any) => {
    // formik.setFieldValue("cedula", value)
    let item = value[0]
    if (item) {
      formik.setFieldValue("firstName", item.nombres)
      formik.setFieldValue("lastName", item.apellido1)
      formik.setFieldValue("secondLastName", item.apellido2)
      formik.setFieldValue("birthdate", item.fechaNacimiento.substring(0, 10))
      if (item.imagen) {
        let file = Base64ToFile(
          "data:image/jpeg;base64," + item.imagen,
          item.cedula + ".jpg"
        )
        let bodyData = new FormData()
        bodyData.append("path", file)
        const request = await API.postAction(`File/Upload`, bodyData, {
          headers: { "Content-Type": "multipart/form-data" },
        })

        formik.setFieldValue("identifierPicture", request.data.response)
        // let bodyData = new FormData()
        // bodyData.append("path", file)
        // const request = await API.postAction(`File/Upload`, bodyData, {
        //   headers: { "Content-Type": "multipart/form-data" },
        // })
        // if (field.includes("photo")) setPhoto(request.data.response)
        // props.updateField(model.name, request.data.response)
      }
      // formik.setFieldValue("phone", item.phone)
      // formik.setFieldValue("position", item.position)
    }
    console.log("relatives", value)
  }

  const handleSubmit = async () => {
    try {
      console.log("values to submit", formik.values)

      let query = {}
      fields.forEach((item: CRUDField) => {
        if (item && item.htmlType !== "separator" && item.name.length > 0) {
          query[item.name] = formik.values[item.name] ?? null
        }
      })
      query["photos"] = formik.values.photos
      query["biometrical"] = formik.values.biometrical
      query["relatives"] = formik.values.relatives
      query["organizationId"] = user.OrganizationId
      console.log(query)
      setIsLoading(true)
      let request = (await API.postAction(`Employee/AddEdit`, query)) as any
      if (request.data.status === "ok") {
        toast.success(t("record saved"))
        props.toggle()
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      setIsLoading(false)
    }
  }

  const bindDataRemote = async () => {
    try {
      if (record === null) return
      console.log("bind data remote", record)
      setIsLoading(true)
      let request = await API.getAction(
        "Employee/Details?id=" + record.employeeId
      )
      if (request.data.status === "ok") {
        setRecordRelatives(request.data.response.relatives)
        fillFormikObject(request.data.response)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }
  // EVENTS
  useEffect(() => {
    prepareFormikObject()
  }, [])

  useEffect(() => {
    setRecord(props.record)
  }, [props.record])

  useEffect(() => {
    console.log("load remote content")
    bindDataRemote()
  }, [record])

  return (
    <Modal
      show={true}
      onHide={props.toggle}
      size={props.modalSize ? props.modalSize : "lg"}
      className={props.cssClass}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex gap-2 flex-col px-4">
          <div className="row">
            {fields &&
              fields
                .filter(x => x.htmlType !== "hidden" && x.groupNumber === 0)
                .map((item, index) => {
                  return (
                    <FieldSelector
                      key={index}
                      model={{
                        ...item,
                        readOnly: props.readOnly ?? false,
                      }}
                      updateField={updateField}
                      resultadosCedula={resultadosCedula}
                      obj={formik.values}
                      value={
                        formik.values[item.name as keyof typeof formik.values]
                      }
                      errors={formik.errors}
                    />
                  )
                })}

            {fields &&
              fields
                .filter(x => x.htmlType !== "hidden" && x.groupNumber === 1)
                .map((item, index) => {
                  return (
                    <FieldSelector
                      key={index}
                      model={{
                        ...item,
                        readOnly: props.readOnly ?? false,
                      }}
                      updateField={updateField}
                      resultadosCedula={resultadosCedula}
                      obj={formik.values}
                      value={
                        formik.values[item.name as keyof typeof formik.values]
                      }
                      errors={formik.errors}
                    />
                  )
                })}
            {fields &&
              fields
                .filter(x => x.htmlType !== "hidden" && x.groupNumber === 2)
                .map((item, index) => {
                  return (
                    <FieldSelector
                      key={index}
                      model={{
                        ...item,
                        readOnly: props.readOnly ?? false,
                      }}
                      updateField={updateField}
                      resultadosCedula={resultadosCedula}
                      obj={formik.values}
                      value={
                        formik.values[item.name as keyof typeof formik.values]
                      }
                      errors={formik.errors}
                    />
                  )
                })}
            {/* PARENTS */}
            <EmployeeRelativeAddEdit
              parent={formik.values}
              relatives={recordRelatives}
              readOnly={props.readOnly}
              updateRelativeArray={updateRelativeArray}
            />

            {fields
              .filter(x => x.htmlType !== "hidden" && x.groupNumber === 3)
              .map((item, index) => {
                return (
                  <FieldSelector
                    key={index}
                    model={{
                      ...item,
                      readOnly: props.readOnly ?? false,
                    }}
                    updateField={updateField}
                    resultadosCedula={resultadosCedula}
                    obj={formik.values}
                    value={
                      formik.values[item.name as keyof typeof formik.values]
                    }
                    errors={formik.errors}
                  />
                )
              })}

            {fields
              .filter(x => x.htmlType !== "hidden" && x.groupNumber === 4)
              .map((item, index) => {
                return (
                  <FieldSelector
                    key={index}
                    model={{
                      ...item,
                      readOnly: props.readOnly ?? false,
                    }}
                    updateField={updateField}
                    resultadosCedula={resultadosCedula}
                    obj={formik.values}
                    value={
                      formik.values[item.name as keyof typeof formik.values]
                    }
                    errors={formik.errors}
                  />
                )
              })}

            {fields
              .filter(x => x.htmlType !== "hidden" && x.groupNumber === 5)
              .map((item, index) => {
                return (
                  <FieldSelector
                    key={index}
                    model={{
                      ...item,
                      readOnly: props.readOnly ?? false,
                    }}
                    updateField={updateField}
                    resultadosCedula={resultadosCedula}
                    obj={formik.values}
                    value={
                      formik.values[item.name as keyof typeof formik.values]
                    }
                    errors={formik.errors}
                  />
                )
              })}

            {fields
              .filter(x => x.htmlType !== "hidden" && x.groupNumber === 7)
              .map((item, index) => {
                return (
                  <FieldSelector
                    key={index}
                    model={{
                      ...item,
                      readOnly: props.readOnly ?? false,
                    }}
                    updateField={updateField}
                    resultadosCedula={resultadosCedula}
                    obj={formik.values}
                    value={
                      formik.values[item.name as keyof typeof formik.values]
                    }
                    errors={formik.errors}
                  />
                )
              })}
            {/* CONTRACTOR AND SUB_CONTRATORS */}
            <div className="mb-4"></div>
            <CRUD
              id="contractors"
              cssClass="modalRight"
              isModal={false}
              parentId="employeeId"
              parentValue={record?.employeeId}
              headerStyle="justButtons"
              showModal={props.show}
              filterFields={[
                {
                  name: "employeeId",
                  htmlType: "hidden",
                  dbType: "string",
                  title: t("employee") ?? "",
                  containerClass: "col-12 col-md-6",
                  required: false,
                  value: record?.employeeId,
                },
              ]}
              toggleCRUD={props.toggle}
              title={t("Contratista y subcontratista") ?? ""}
              getRecordsMethod="post"
              readOnly={props.readOnly}
              getRecordsPath={`contractor/list`}
              addEditRecordPath={`contractor/AddEdit`}
              deleteRecordPath="contractor/Delete"
              fieldsList={[
                {
                  name: "companyTypeName",
                  title: t("Type"),
                },
                {
                  name: "companyName",
                  title: t("Company"),
                },

                {
                  name: "representative",
                  title: t("Representative"),
                },
              ]}
              fields={[
                {
                  name: "id",
                  htmlType: "hidden",
                  dbType: "string",
                },
                {
                  name: "companyTypeId",
                  htmlType: "select",
                  dbType: "string",
                  title: t("Type") ?? "",
                  containerClass: "col-12 col-md-6",
                  required: true,
                  dataSourceTextField: "name",
                  dataSourceValueField: "id",
                  dataSourceLocal: [
                    {
                      id: 1,
                      name: t("Contractor"),
                    },
                    {
                      id: 3,
                      name: t("Intermediario"),
                    },
                    {
                      id: 2,
                      name: t("Subcontractor"),
                    },
                  ],
                },
                {
                  name: "rnc",
                  htmlType: "searchRNC",
                  dbType: "string",
                  title: t("RNC") ?? "",
                  containerClass: "col-12 col-md-6",
                  required: false,
                },
                {
                  name: "companyName",
                  htmlType: "text",
                  dbType: "string",
                  title: t("Company Name") ?? "",
                  containerClass: "col-12 col-md-6",
                  required: true,
                },
                {
                  name: "representativeIdentificator",
                  htmlType: "cedula",
                  dbType: "string",
                  title: t("Cédula Representante") ?? "",
                  containerClass: "col-12 col-md-6",
                  required: false,
                },
                {
                  name: "representative",
                  htmlType: "text",
                  dbType: "string",
                  title: t("Representative") ?? "",
                  containerClass: "col-12 col-md-6",
                  required: false,
                },
                {
                  name: "phone",
                  htmlType: "text",
                  dbType: "string",
                  title: t("Phone") ?? "",
                  containerClass: "col-12 col-md-6",
                  required: false,
                },
                {
                  name: "notes",
                  htmlType: "textarea",
                  dbType: "string",
                  title: t("Notes") ?? "",
                  containerClass: "col-12",
                  required: false,
                },
                {
                  name: "employeeId",
                  htmlType: "hidden",
                  dbType: "string",
                  title: t("employee") ?? "",
                  containerClass: "col-12 col-md-6",
                  required: false,
                  value: record?.employeeId,
                },
              ]}
            />

            <div className="mb-4"></div>
            <CRUD
              cssClass="modalRight"
              isModal={false}
              parentId="employeeId"
              parentValue={record?.employeeId}
              headerStyle="justButtons"
              showModal={props.show}
              toggleCRUD={props.toggle}
              title={t("Previous Work") ?? ""}
              getRecordsMethod="post"
              readOnly={props.readOnly}
              getRecordsPath={`previousWork/list`}
              addEditRecordPath={`previousWork/AddEdit`}
              deleteRecordPath="previousWork/Delete"
              fieldsList={[
                {
                  name: "previousEmployee",
                  title: t("Previous Employee"),
                },
                {
                  name: "previousRole",
                  title: t("Previous Role"),
                },
                {
                  name: "salary",
                  title: t("Salary"),
                },
              ]}
              fields={[
                {
                  name: "id",
                  htmlType: "hidden",
                  dbType: "string",
                },
                {
                  name: "previousEmployee",
                  htmlType: "text",
                  dbType: "string",
                  title: t("Previous Employee") ?? "",
                  containerClass: "col-12 col-md-6",
                  required: true,
                },

                {
                  name: "previousRole",
                  htmlType: "text",
                  dbType: "string",
                  title: t("Previous Role") ?? "",
                  containerClass: "col-12 col-md-6",
                  required: true,
                },
                {
                  name: "salary",
                  htmlType: "text",
                  dbType: "string",
                  title: t("Salary") ?? "",
                  containerClass: "col-12 col-md-6",
                  required: false,
                },

                {
                  name: "employeeId",
                  htmlType: "hidden",
                  dbType: "string",
                  title: t("employee") ?? "",
                  containerClass: "col-12 col-md-6",
                  required: false,
                  value: record?.employeeId,
                },
              ]}
            />

            {/* Sanctions */}
            {fields
              .filter(x => x.htmlType !== "hidden" && x.groupNumber === 8)
              .map((item, index) => {
                return (
                  <FieldSelector
                    key={index}
                    model={{
                      ...item,
                      readOnly: props.readOnly ?? false,
                    }}
                    updateField={updateField}
                    resultadosCedula={resultadosCedula}
                    obj={formik.values}
                    value={
                      formik.values[item.name as keyof typeof formik.values]
                    }
                    errors={formik.errors}
                  />
                )
              })}
            {fields
              .filter(x => x.htmlType !== "hidden" && x.groupNumber === 6)
              .map((item, index) => {
                return (
                  <FieldSelector
                    key={index}
                    model={{
                      ...item,
                      readOnly: props.readOnly ?? false,
                    }}
                    updateField={updateField}
                    resultadosCedula={resultadosCedula}
                    obj={formik.values}
                    value={
                      formik.values[item.name as keyof typeof formik.values]
                    }
                    errors={formik.errors}
                  />
                )
              })}
            <EmployeePhotoTypes
              parent={formik.values}
              updatePhotosArray={updatePhotosArray}
            />

            {fields
              .filter(x => x.htmlType !== "hidden" && x.groupNumber === 9)
              .map((item, index) => {
                return (
                  <FieldSelector
                    key={index}
                    model={{
                      ...item,
                      readOnly: props.readOnly ?? false,
                    }}
                    updateField={updateField}
                    resultadosCedula={resultadosCedula}
                    obj={formik.values}
                    value={
                      formik.values[item.name as keyof typeof formik.values]
                    }
                    errors={formik.errors}
                  />
                )
              })}
            {/* Biometrical Photos */}
            <EmployeeBiometricalPhoto
              parent={formik.values}
              updateBiometricalPhotosArray={updateBiometricalPhotosArray}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        {!props.readOnly && (
          <div className="modal-action m-0 p-2 ">
            {isLoading && (
              <div className="me-4 d-inline-block">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            <button
              className="btn btn-secondary me-2"
              disabled={isLoading}
              onClick={() => props.toggle()}
              type="button"
            >
              {t("Cancel")}
            </button>

            <button
              className="btn btn-primary text-white"
              disabled={isLoading}
              onClick={() => formik.submitForm()}
              type="button"
            >
              {t("Save")}
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default EmployeAddEdit
