import React, { useEffect, useState } from "react"
import { Card, Col, Container, Modal, Row } from "react-bootstrap"
import EmployeDTO from "../Employees/EmployeDTO"
import CRUDField from "../core/genericCRUD/CRUDField"
import { useTranslation } from "react-i18next"

import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"

import Filter from "../core/Filter/Filter"
import VerificarAntecedentesFilterFields from "./VerificarAntecedentes.filterFields"
import Settings from "../../common/Settings"

import ActionBar from "../core/ActionBar"
import VerificarAntecedentesListView from "./Nacionals/VerificarAntecedentesListView"
import VerificarAntecedentesCardView from "./Nacionals/VerificarAntecedentesCardView"
import Pagination from "../core/Pagination"

import VerificarAntecedentesFilterFieldsForeigners from "./VerificarAntecedentes.filterFieldsForeigners"
import ForeignersVerificarAntecedentesListView from "./Foreigners/ForeignersVerificarAntecedentesListView"
import ForeignersVerificarAntecedentesCardView from "./Foreigners/ForeignersVerificarAntecedentesCardView"

const VerificarAntecedentes = props => {
  // PROPERTIES
  const [typeOfSearch, setTypeOfSearch] = useState<
    "foreigners" | "residents" | null
  >(null)
  const [viewMode, setViewMode] = useState<"list" | "grid">("list")
  const [page, setPage] = useState(0)
  const [quantity, setQuantity] = useState(20)
  const [totalPages, setTotalPages] = useState(1)

  const [consentUploaded, setConsentUploaded] = useState<boolean>(false)
  const [records, setRecords] = useState<any>([])
  const [filterValues, setFilterValues] = useState<any>({})

  const [filterFields, setFilterFields] = useState(
    VerificarAntecedentesFilterFields
  )
  const getFilterValues = obj => {
    setFilterValues(obj)
  }
  const fields: CRUDField[] = EmployeDTO
  const { t } = useTranslation("global")

  const [isLoading, setIsLoading] = useState(false)
  const user = parsedUser()

  // METHODS

  const onPageChanged = (obj: number) => {
    setPage(obj)
  }
  const handleFileChange = async e => {
    const file = e.target.files[0]
    if (file) {
      setConsentUploaded(true)
    }
  }
  const bindDataRemote = async () => {
    try {
      setIsLoading(true)

      if (Object.keys(filterValues).length === 0) {
        return
      }

      // Check search type
      if (typeOfSearch === "foreigners") {
        let query = {}

        Object.keys(filterValues).forEach(key => {
          query[key] = filterValues[key]
        })
        query["page"] = page
        query["quantity"] = quantity
        setIsLoading(true)
        let request = await API.postAction("registroDetenidos/list", query)

        setIsLoading(false)
        if (request.data.status === "ok") {
          setRecords(request.data.response)
          setTotalPages(request.data.quantityOfPages)
          // setPage(request.data.page)
        }
      } else {
        let query = ""

        Object.keys(filterValues).forEach(key => {
          query += "&" + key + "=" + filterValues[key]
        })

        query += "&page=" + page
        setIsLoading(true)
        let routePath = "busqueda/nacionales" + "?" + query
        let request = await API.getAction(routePath)
        console.log(request)
        if (request.data.status === "ok") {
          setRecords(request.data.response)
          setTotalPages(request.data.quantityOfPages)
          // setPage(request.data.page)
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }
  // EVENTS
  useEffect(() => {
    bindDataRemote()
  }, [page])
  useEffect(() => {
    if (typeOfSearch) {
      if (typeOfSearch === "foreigners") {
        setFilterFields(VerificarAntecedentesFilterFieldsForeigners)
      } else {
        setFilterFields(VerificarAntecedentesFilterFields)
      }
    }
  }, [typeOfSearch])

  useEffect(() => {
    if (filterValues) {
      setPage(0)
      setTimeout(() => {
        bindDataRemote()
      }, 500)
    }
  }, [filterValues])

  if (!typeOfSearch) {
    return (
      <Container>
        <Card className="mb-4">
          <Card.Header>
            <Card.Title>{t("Especifique su tipo de búsqueda")}</Card.Title>
          </Card.Header>
          <Card.Body className="">
            <Row>
              <Col>
                <button
                  className="btn btn-primary btn-block mb-2 btn-lg"
                  onClick={() => setTypeOfSearch("foreigners")}
                >
                  <i className="fas  fa-2x fa-globe-americas me-2"></i>
                  <br />
                  Extranjeros
                </button>
              </Col>
              <Col>
                <button
                  className="btn btn-primary btn-block mb-2 btn-lg"
                  onClick={() => setTypeOfSearch("residents")}
                >
                  <i className="fas fa-2x fa-users me-2"></i>
                  <br />
                  Nacionales
                </button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    )
  }

  return (
    <Container>
      <ActionBar
        title={t("Verificar y Recomendación de Postulantes")}
        actions={
          <>
            <div className="ms-2 btn-group">
              <button
                onClick={() => setViewMode("grid")}
                className={
                  "btn btn-sm " +
                  (viewMode === "grid" ? "btn-primary" : "btn-secondary")
                }
              >
                Tarjeta
              </button>
              <button
                onClick={() => setViewMode("list")}
                className={
                  "btn btn-sm " +
                  (viewMode === "list" ? "btn-primary" : "btn-secondary")
                }
              >
                Lista
              </button>
            </div>
          </>
        }
      ></ActionBar>

      <div className="my-4">
        <button
          className="btn btn-secondary"
          onClick={() => {
            setTypeOfSearch(null)
            setRecords([])
          }}
        >
          <i className="fa fa-chevron-left me-2"></i>
          <span>{t("Volver")}</span>
        </button>
        {typeOfSearch === "residents" && !consentUploaded ? (
          <Card className="mb-4 mx-auto">
            <Card.Header>
              <Card.Title>{t("Cargar Consentimiento")}</Card.Title>
            </Card.Header>
            <Card.Body className="p-0 pt-2">
              <Row>
                <Col>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="form-control"
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-end">
              <button
                className="btn btn-primary "
                onClick={() => setConsentUploaded(true)}
              >
                Cargar Consentimiento
              </button>
            </Card.Footer>
          </Card>
        ) : (
          <Filter
            // toggleFilter={toggleFilter}
            title={t("Verificar Por:")}
            fields={filterFields}
            setFilterFields={getFilterValues}
            filterAction={() => {
              console.log("filter")

              bindDataRemote()
            }}
          />
        )}
      </div>
      {isLoading && (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {viewMode === "list" ? (
        typeOfSearch === "foreigners" ? (
          <ForeignersVerificarAntecedentesListView
            records={records}
            isLoading={isLoading}
          />
        ) : (
          <VerificarAntecedentesListView
            records={records}
            isLoading={isLoading}
          />
        )
      ) : viewMode === "grid" ? (
        typeOfSearch === "foreigners" ? (
          <ForeignersVerificarAntecedentesCardView
            records={records}
            isLoading={isLoading}
          />
        ) : (
          <VerificarAntecedentesCardView
            records={records}
            isLoading={isLoading}
          />
        )
      ) : null}
      <Card className="mb-4">
        <Card.Body className="p-0 pt-2">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={onPageChanged}
          />
        </Card.Body>
      </Card>
    </Container>
  )
}

export default VerificarAntecedentes
