import React, { useState, useEffect } from "react"
import { db, defaultDataDB } from "./common/db"
import { Route, Switch, useHistory } from "react-router-dom"
import { Routes } from "./routes"
import ErrorBoundary from "./components/Common/BoundaryError"

import Preloader from "./components/Preloader"

import PreferencesContextProvider from "./Context/preferences"

import { parsedUser } from "./common/GetCurrentUser"

import { Toaster } from "react-hot-toast"

import { useLiveQuery } from "dexie-react-hooks"
import Sidebar from "./components/Common/Sidebar/Sidebar"

import routesWithComponent from "./pages/routesWithComponent.tsx"
import Navbar from "./components/Common/Navbar"

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const user = parsedUser()
  if (!user) {
    window.location = Routes.SignIn.path
    return
  }
  const history = useHistory()
  const settings = useLiveQuery(() =>
    db.settings.where({ id: user.ID }).first()
  )
  const [sidebarToggle, setSidebarToggle] = useState(true)
  const [loaded, setLoaded] = useState(false)

  const validateUser = async () => {
    if (!user) {
      console.log("ENTRO", user)

      history.push(Routes.SignIn.path)
    } else {
      //Check for settings
      let settingExist = await db.settings.where({ id: user.ID }).first()

      if (!settingExist) {
        await db.settings.put({
          id: user.ID,
          application: "finance",
          language: "en",
        })
      }
    }
  }

  useEffect(() => {
    validateUser()
    console.log("Settings", settings)
    const timer = setTimeout(() => setLoaded(true), 1000)
    return () => clearTimeout(timer)
  }, [])

  const handleSidebarShow = () => {
    setSidebarToggle(!sidebarToggle)
  }

  return (
    <PreferencesContextProvider>
      <Route
        {...rest}
        render={props => (
          <>
            <Toaster />
            <Preloader show={!loaded} />
            {/* <OmniBar {...props} settings={settings} /> */}
            <Sidebar
              settings={settings}
              sidebarToggle={sidebarToggle}
              handleSidebarShow={handleSidebarShow}
            />
            <main className={sidebarToggle ? "content" : "content ms-5"}>
              <Navbar {...props} />
              <Component {...props} />
              {/* <Footer
                      toggleSettings={toggleSettings}
                      showSettings={showSettings}
                    /> */}
            </main>
          </>
        )}
      />
    </PreferencesContextProvider>
  )
}

const App = () => {
  useEffect(() => {
    defaultDataDB()
  }, [])

  return (
    <ErrorBoundary>
      <Switch>
        {routesWithComponent.map(item => {
          return item.withSidebar ? (
            <RouteWithSidebar
              // key={index}
              exact
              path={item.path}
              component={item.component}
            />
          ) : (
            <Route
              // key={index}
              exact
              path={item.path}
              component={item.component}
            />
          )
          //  return obj
        })}
      </Switch>
    </ErrorBoundary>
  )
}

export default App
