import React from "react"
import CheckProfile from "../components/CheckProfile/CheckProfile"

export default function CheckProfilePage() {
  return (
    <div>
      <CheckProfile />
    </div>
  )
}
