import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const AdminFichadosComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      isModal={false}
      modalSize="sm"
      filterOpen={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("Fichados") ?? ""}
      icon={"fas fa-users"}
      getRecordsPath={`https://api.padron.valegos.com/api/fichados/fichados`}
      addEditRecordPath={`users/AddEditAdminUsers?currentUserId=${user.ID}`}
      deleteRecordPath="expense/DeleteExpenseType"
      filterFields={[
        {
          name: "id",
          htmlType: "text",
          dbType: "string",
          title: t("Cédula") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },
        {
          name: "nombre",
          htmlType: "text",
          dbType: "string",
          title: t("Nombre") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },
        {
          name: "apellido1",
          htmlType: "text",
          dbType: "string",
          title: t("Apellido 1") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },

        {
          name: "apellido2",
          htmlType: "text",
          dbType: "string",
          title: t("Apellido 2") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },
        {
          name: "alias",
          htmlType: "text",
          dbType: "string",
          title: t("Alias") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },
      ]}
      fieldsList={[
        {
          name: "cedula",
          title: t("Cédula"),
        },
        {
          name: "nombre",
          title: t("Nombre"),
        },
        {
          name: "apellido1",
          title: t("Apellido 1"),
        },
        {
          name: "apellido2",
          title: t("Apellido 2"),
        },
        {
          name: "alias",
          title: t("Alias"),
        },
        {
          name: "fechaSometido",
          title: t("Fecha Sometido"),
          htmlType: "date",
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "firstName",
          htmlType: "text",
          dbType: "string",
          title: t("Name") ?? "",
          containerClass: "col-6",
          required: true,
        },
        {
          name: "lastName",
          htmlType: "text",
          dbType: "string",
          title: t("Last Name") ?? "",
          containerClass: "col-6",
          required: true,
        },
        {
          name: "email",
          htmlType: "email",
          dbType: "string",
          title: t("email") ?? "",
          containerClass: "col-12",
          required: true,
        },
        {
          name: "password",
          htmlType: "password",
          dbType: "string",
          title: t("Password") ?? "",
          containerClass: "col-12",
          required: true,
        },
      ]}
    />
  )
}

export default AdminFichadosComponent
