import React from "react"
import { Col, Row } from "react-bootstrap"

const ActionBar = props => {
  return props.headerStyle && props.headerStyle === "justButtons" ? (
    <Row>
      <Col style={{ fontWeight: "bold" }}>
        {props.icon ? (
          <span className="me-2">
            <i className={props.icon + " fa-2x"}></i>
          </span>
        ) : null}
        <h4 className="d-inline">{props.title}</h4>
      </Col>
      <Col xs={"auto"} className="text-end">
        {props.actions}
      </Col>
      <Col xs={12}>
        <hr />
      </Col>
    </Row>
  ) : (
    <div className="card shadow-sm">
      <div className="card-body py-2 px-4">
        <Row>
          <Col style={{ fontWeight: "bold" }}>
            {props.icon ? (
              <span className="me-2">
                <i className={props.icon + " fa-2x"}></i>
              </span>
            ) : null}
            <h4 className="d-inline ">{props.title}</h4>
          </Col>
          <Col className="text-end">{props.actions}</Col>
        </Row>
      </div>
    </div>
  )
}

export default ActionBar
