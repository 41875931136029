import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Settings from "../../common/Settings"

const HomeComponentCardView = ({ records, toggleAddEdit, deleteRecord }) => {
  const { t } = useTranslation("global")
  return (
    <Row>
      {records &&
        records.map((item: any, index: number) => {
          return (
            <Col xs={12} md={4} lg={3} key={index}>
              <Card className="mb-4">
                <Card.Body className="p-0 ">
                  {item.identifierPicture && (
                    <img
                      alt=""
                      className="card-img-top"
                      style={{ maxHeight: "175px" }}
                      id="image"
                      src={`${Settings.BasePath}/files/${item.identifierPicture}`}
                    />
                  )}
                  <p className="text-center my-2 card-text text-uppercase">
                    {item.firstName} {item.lastName}
                    <br />
                    <b>{item.identifier}</b>
                  </p>
                </Card.Body>
                <Card.Footer className="text-center">
                  <div className="btn-group">
                    <button
                      className="btn btn-sm btn-primary  d-xs-none"
                      type="button"
                      onClick={() => toggleAddEdit(item)}
                    >
                      <i className="fa fa-edit" />
                    </button>
                    <button
                      className="btn btn-sm btn-primary  d-xs-none"
                      type="button"
                      onClick={() => {
                        deleteRecord(item)
                      }}
                    >
                      <i className="fa fa-trash text-danger" />
                    </button>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          )
        })}
    </Row>
  )
}

export default HomeComponentCardView
