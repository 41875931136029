import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const AdminExtranjerosHaitianosComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      isModal={false}
      modalSize="sm"
      showModal={props.show}
      toggleCRUD={props.toggle}
      filterOpen={true}
      title={t("Extranjeros Haitianos") ?? ""}
      icon={"fas fa-users"}
      getRecordsPath={`https://api.padron.valegos.com/api/extranjeros/extranjerosHaitianos/`}
      addEditRecordPath={`users/AddEditAdminUsers?currentUserId=${user.ID}`}
      deleteRecordPath="expense/DeleteExpenseType"
      filterFields={[
        {
          name: "nombre",
          htmlType: "text",
          dbType: "string",
          title: t("Nombre") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },
        {
          name: "apellidos",
          htmlType: "text",
          dbType: "string",
          title: t("Apellidos") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },
        {
          name: "alias",
          htmlType: "text",
          dbType: "string",
          title: t("Alias") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },
      ]}
      fieldsList={[
        {
          name: "nombre",
          title: t("Nombre"),
        },
        {
          name: "apellidos",
          title: t("Apellidos"),
        },
        {
          name: "alias",
          title: t("Alias"),
        },
        {
          name: "nacionalidad",
          title: t("Nacionalidad"),
        },
        {
          name: "estatusResidencia",
          title: t("Estatus Residencia"),
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "firstName",
          htmlType: "text",
          dbType: "string",
          title: t("Name") ?? "",
          containerClass: "col-6",
          required: true,
        },
        {
          name: "lastName",
          htmlType: "text",
          dbType: "string",
          title: t("Last Name") ?? "",
          containerClass: "col-6",
          required: true,
        },
        {
          name: "email",
          htmlType: "email",
          dbType: "string",
          title: t("email") ?? "",
          containerClass: "col-12",
          required: true,
        },
        {
          name: "password",
          htmlType: "password",
          dbType: "string",
          title: t("Password") ?? "",
          containerClass: "col-12",
          required: true,
        },
      ]}
    />
  )
}

export default AdminExtranjerosHaitianosComponent
