import React, { useEffect, useState } from "react"
import { Card, Col, Modal, Row } from "react-bootstrap"
import ComplainDTO from "./ComplainDTO"
import CRUDField from "../core/genericCRUD/CRUDField"
import FieldSelector from "../core/FieldSelector"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import * as yup from "yup"
import yupTypeValidator from "../../common/YupTypeValidator"
import API from "../../common/API"

import { toast } from "react-toastify"
import { set } from "lodash"

const ComplainAddEdit = props => {
  // PROPERTIES
  const [record, setRecord] = useState<any>(null)
  const [recordRelatives, setRecordRelatives] = useState<any>([])
  const fields: CRUDField[] = ComplainDTO
  const { t } = useTranslation("global")
  const [formikInitialObject, setFormikInitialObject] = useState<any>({})
  const [validationsSchema, setValidationSchema] =
    useState<yup.ObjectSchema<any>>()
  const [autorization, setAutorization] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: formikInitialObject,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: () => handleSubmit(),
  })
  type YupSchema =
    | yup.StringSchema
    | yup.BooleanSchema
    | yup.NumberSchema
    | yup.DateSchema
  // METHODS

  const fillFormikObject = (record: any) => {
    fields.forEach((item: CRUDField) => {
      if (item && item.name.length > 0) {
        //console.log("field name", item.name, "RecordValue ", record[item.name]);
        if (record && record[item.name]) {
          formik.setFieldValue(item.name, record[item.name])
        }
      }
    })
  }

  const prepareFormikObject = () => {
    const initialObject: { [key: string]: string } = {}
    const initialObjectValidation: { [key: string]: YupSchema } = {}

    if (fields) {
      fields.forEach((item: CRUDField) => {
        // if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.name] = item.value

        if (item.required) {
          const schema = yupTypeValidator(item)
          if (schema) {
            initialObjectValidation[item.name] = schema
          }
        }
      })
    }

    // formik.initialValues = initialObject;
    setFormikInitialObject(initialObject)
    setValidationSchema(yup.object().shape(initialObjectValidation))
  }

  const updateField = (fieldName: string, fieldValue: any) => {
    console.log(fieldName, fieldValue)
    formik.setFieldValue(fieldName, fieldValue)
  }

  const selectMapSearch = async (value: any) => {
    formik.setFieldValue("locationLatitude", value.geometry.location.lat)
    formik.setFieldValue("locationLongitude", value.geometry.location.lng)
    formik.setFieldValue("locationMap", {
      lat: value.geometry.location.lat,
      lng: value.geometry.location.lng,
    })
  }
  const resultadosCedula = async (value: any) => {
    // formik.setFieldValue("cedula", value)
    let item = value[0]
    if (item) {
      formik.setFieldValue("complainFirstName", item.nombres)
      formik.setFieldValue("complainLastName", item.apellido1)

      // formik.setFieldValue("phone", item.phone)
      // formik.setFieldValue("position", item.position)
    }
    console.log("relatives", value)
  }

  const handleSubmit = async () => {
    try {
      console.log("values to submit", formik.values)

      let query: any = {}
      fields.forEach((item: CRUDField) => {
        if (item && item.htmlType !== "separator" && item.name.length > 0) {
          query[item.name] = formik.values[item.name] ?? null
        }
      })

      // add media
      let media = filesObj.map(item => {
        return {
          path: item,
          id: null,
          complainId: query.id,
        }
      })
      query.media = media
      console.log(query)
      query.authorization = autorization
      setIsLoading(true)
      let request = (await API.postAction(`complain/AddEdit`, query)) as any
      if (request.data.status === "ok") {
        toast.success(t("record saved"))
        props.toggle()
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      setIsLoading(false)
    }
  }

  const bindDataRemote = async () => {
    try {
      if (record === null) return
      console.log("bind data remote", record)
      setIsLoading(true)
      let request = await API.getAction(
        "complain/Details?id=" + record.employeeId
      )
      if (request.data.status === "ok") {
        setRecordRelatives(request.data.response.relatives)
        fillFormikObject(request.data.response)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const [filesObj, setFilesObj] = useState<any>([])

  // return a fontawesome icon based on the file extension
  const iconBasedOnExtension = (fileName: string) => {
    const ext = fileName.split(".").pop()
    switch (ext) {
      case "pdf":
        return "fa fa-file-pdf"
      case "doc":
      case "docx":
        return "fa fa-file-word"
      case "jpg":
      case "jpeg":
      case "png":
        return "fa fa-file-image"
      default:
        return "fa fa-file"
    }
  }
  const removeFile = async (item: string) => {
    const request = await API.getAction(`File/delete?fileName=${item}`)
    setFilesObj(filesObj.filter((f: any) => f !== item))
  }

  const uploadFiles = async (obj, field) => {
    let files = Array.from(obj.target.files)

    files.forEach(async (file: any) => {
      console.log(file)
      // Ensure it's an image
      // if (file.type.match(/image.*/)) {
      console.log("An image has been loaded")
      let bodyData = new FormData()
      bodyData.append("path", file)
      const request = await API.postAction(`File/Upload`, bodyData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      setFilesObj([...filesObj, request.data.response])
      // formik.setFieldValue(field, request.data.response)
      // }
    })
  }
  // EVENTS
  useEffect(() => {
    prepareFormikObject()
  }, [])

  useEffect(() => {
    setRecord(props.record)
  }, [props.record])

  useEffect(() => {
    console.log("load remote content")
    bindDataRemote()
  }, [record])

  return (
    <Modal
      show={true}
      onHide={props.toggle}
      size={props.modalSize ? props.modalSize : "lg"}
      className={props.cssClass}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Complain")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex gap-2 flex-col px-4">
          <Row>
            {fields &&
              fields
                .filter(x => x.htmlType !== "hidden")
                .map((item, index) => {
                  return (
                    <FieldSelector
                      key={index}
                      model={{
                        ...item,
                        readOnly: props.readOnly ?? false,
                      }}
                      updateField={updateField}
                      resultadosCedula={resultadosCedula}
                      selectMapSearch={selectMapSearch}
                      obj={formik.values}
                      value={
                        formik.values[item.name as keyof typeof formik.values]
                      }
                      errors={formik.errors}
                    />
                  )
                })}
            {/* MULTIMEDIA */}
            <Col xs={12}>
              <h5 className="mt-4">{t("Multimedia")}</h5>
              <hr />
              <input
                id="imageUploadPrimary"
                className="form-control d-none"
                type="file"
                multiple
                capture
                accept="image/*, .pdf,.avi,.mp4,.wav "
                onChange={obj => uploadFiles(obj, "files")}
              />
              <label
                className="btn btn-secondary btn-sm btn-block"
                htmlFor="imageUploadPrimary"
              >
                <i className="fas fa-camera me-2"></i>
                Subir
              </label>
            </Col>
            {filesObj.length > 0 &&
              filesObj.map((item, index) => {
                return (
                  <Col key={index} xs={12} md={4} className="my-2">
                    <Card>
                      <Card.Header className="p-1">
                        <div
                          className="d-flex justify-content-between text-truncate"
                          title={item}
                        >
                          <span>{item}</span>
                        </div>
                      </Card.Header>
                      <Card.Body className="p-1  py-4 text-center">
                        <i
                          className={"fa-2x " + iconBasedOnExtension(item)}
                        ></i>
                      </Card.Body>
                      <Card.Footer className="p-1 text-center">
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => removeFile(item)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </Card.Footer>
                    </Card>
                  </Col>
                )
              })}
          </Row>
        </div>
      </Modal.Body>

      <Modal.Footer className="p-1 px-2">
        <Row className="w-100">
          <Col>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              checked={autorization}
              onClick={() => {
                setAutorization(!autorization)
              }}
            />
            <label className="form-check-label ms-2" htmlFor="exampleCheck1">
              AUTORIZO FORMALIZACIÓN
            </label>
          </Col>
          <Col className="text-end">
            {!props.readOnly && (
              <div className="modal-action m-0 p-2 ">
                {isLoading && (
                  <div className="me-4 d-inline-block">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
                <button
                  className="btn btn-secondary me-2"
                  disabled={isLoading}
                  onClick={() => props.toggle()}
                  type="button"
                >
                  {t("Cancel")}
                </button>

                <button
                  className="btn btn-primary text-white"
                  disabled={isLoading}
                  onClick={() => formik.submitForm()}
                  type="button"
                >
                  {t("Save")}
                </button>
              </div>
            )}
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default ComplainAddEdit
