import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const AdminDetenidos = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      isModal={false}
      modalSize="sm"
      filterOpen={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("Detenidos") ?? ""}
      icon={"fas fa-users"}
      getRecordsMethod="post"
      getRecordsPath={`RegistroDetenidos/List`}
      addEditRecordPath={``}
      deleteRecordPath=""
      filterFields={[
        {
          name: "cedula",
          htmlType: "text",
          dbType: "string",
          title: t("Cedula") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },

        {
          name: "nombres",
          htmlType: "text",
          dbType: "string",
          title: t("Nombres") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },
        {
          name: "apellidos",
          htmlType: "text",
          dbType: "string",
          title: t("Apellidos") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },

        {
          name: "alias",
          htmlType: "text",
          dbType: "string",
          title: t("Alias") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },
        // {
        //   name: "condicionJuridica",
        //   htmlType: "select",
        //   dbType: "string",
        //   title: t("Condición Juridica") ?? "",
        //   containerClass: "col-md-4 col-12",
        //   required: false,
        //   dataSourceTextField: "name",
        //   dataSourceValueField: "id",
        //   dataSourceLocal: [
        //     { name: "PREVENTIVO", id: "PREVENTIVO" },
        //     { name: "CONDENADO", id: "CONDENADO" },
        //   ],
        // },
        // {
        //   name: "centro",
        //   htmlType: "select",
        //   dbType: "string",
        //   title: t("Centro") ?? "",
        //   containerClass: "col-md-4 col-12",
        //   required: false,
        //   dataSourceTextField: "nombre",
        //   dataSourceValueField: "id",
        //   dataSource: "centros/list",
        //   datasourceMethod: "POST",
        // },
      ]}
      //
      //multas, condenas, condicion juridica, edad, , centro, ficha
      fieldsList={[
        {
          name: "idFotoFrente",
          title: t(""),
          htmlType: "image",
          cssClass: "p-0 m-0",
        },
        // {
        //   name: "centroId",
        //   title: t("Centro"),
        // },
        // {
        //   name: "ficha",
        //   title: t("Ficha"),
        // },
        {
          name: "nombre",
          title: t("Nombres"),
        },
        {
          name: "apellidos",
          title: t("Apellidos"),
        },
        {
          name: "cedula",
          title: t("Cédula"),
        },
        {
          name: "alias1",
          title: t("Alias"),
        },
        // {
        //   name: "nacionalidad",
        //   title: t("Nacionalidad"),
        // },
        // {
        //   name: "edad",
        //   title: t("Edad"),
        // },
        // {
        //   name: "violacion",
        //   title: t("Violacion"),
        // },

        {
          name: "fechaDetenido",
          title: t("Fecha Ingreso"),
          htmlType: "date",
        },
        // {
        //   name: "condicionJuridica",
        //   title: t("Condición Juridica"),
        // },
        // {
        //   name: "condena",
        //   title: t("Condena"),
        // },
        // {
        //   name: "multas",
        //   title: t("Multas"),
        // },
        // {
        //   name: "tribunal",
        //   title: t("Tribunal"),
        // },
      ]}
      fields={[]}
    />
  )
}

export default AdminDetenidos
