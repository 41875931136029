import { useTranslation } from "react-i18next"
import CRUDField from "../core/genericCRUD/CRUDField"

const ComplainDTO: CRUDField[] = [
  {
    name: "id",
    title: "id",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "anonymous",
    title: "Anonymous",
    htmlType: "select",
    dbType: "bit",
    required: true,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
    dataSourceLocal: [
      { name: "Si", id: true },
      { name: "No", id: false },
    ],
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    value: false,
  },
  {
    name: "",
    title: "Complainer",
    htmlType: "separator",
    dbType: "string",
    required: false,
    containerClass: " col-12",
    groupNumber: 0,
    dependOf: "anonymous",
    dependOfValue: false,
  },

  {
    name: "cedula",
    title: "Cedula",
    htmlType: "cedula",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
    dependOf: "anonymous",
    dependOfValue: false,
  },
  {
    name: "passport",
    title: "Passport",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
    dependOf: "anonymous",
    dependOfValue: false,
  },

  {
    name: "complainFirstName",
    title: "First Name",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
    dependOf: "anonymous",
    dependOfValue: false,
  },

  {
    name: "complainLastName",
    title: "Last Name",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
    dependOf: "anonymous",
    dependOfValue: false,
  },
  {
    name: "",
    title: "Complain Information",
    htmlType: "separator",
    dbType: "string",
    required: false,
    containerClass: " col-12",
    groupNumber: 0,
  },
  {
    name: "whenItHappen",
    title: "When It Happen",
    htmlType: "datetime",
    dbType: "datetimeoffset",
    required: true,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },

  {
    name: "complainCategoryId",
    title: "Complain type",
    htmlType: "select",
    dbType: "string",
    required: true,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
    datasourceMethod: "POST",
    dataSource: "complainCategory/list",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
  },
  {
    name: "complainCouldYouRecognizeId",
    title: "Can you recognize the",
    htmlType: "select",
    dbType: "string",
    required: true,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
    datasourceMethod: "POST",
    dataSource: "complainCouldYouRecognize/list",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
  },
  {
    name: "alias",
    title: "Name or alias of the Victimary",
    htmlType: "text",
    dbType: "text",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "otherDetails",
    title: "Other Details",
    htmlType: "textarea",
    dbType: "string",
    required: true,
    containerClass: "col-12",
    groupNumber: 0,
  },
  {
    name: "",
    title: "Location Information",
    htmlType: "separator",
    dbType: "string",
    required: false,
    containerClass: " col-12",
    groupNumber: 0,
  },
  {
    name: "typeOfLocationId",
    title: "Type of location",
    htmlType: "select",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
    datasourceMethod: "POST",
    dataSource: "typeOfLocation/list",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
  },
  {
    name: "locationReference",
    title: "Location Reference",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },

  {
    name: "location",
    title: "Address",
    htmlType: "searchMap",
    dbType: "string",
    required: false,
    containerClass: " col-12",
    groupNumber: 0,
  },

  {
    name: "locationLatitude",
    title: "Latitude",
    htmlType: "hidden",
    dbType: "string",
    required: false,

    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  {
    name: "locationLongitude",
    title: "Longitude",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  {
    name: "locationMap",
    title: "Map",
    htmlType: "map",
    dbType: "string",
    required: false,
    containerClass: " col-12",
    groupNumber: 0,
  },
]

export default ComplainDTO
