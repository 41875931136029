import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const AdminDGIIComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      isModal={false}
      modalSize="sm"
      filterOpen={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("DGII") ?? ""}
      icon={"fas fa-users"}
      getRecordsPath={`dgii/contribuyentes`}
      addEditRecordPath={``}
      deleteRecordPath=""
      filterFields={[
        {
          name: "rnc",
          htmlType: "text",
          dbType: "string",
          title: t("RNC") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },
        {
          name: "nombre",
          htmlType: "text",
          dbType: "string",
          title: t("Nombre") ?? "",
          containerClass: "col-md-4 col-12",
          required: false,
        },
      ]}
      fieldsList={[
        {
          name: "rnc",
          title: t("RNC"),
        },
        {
          name: "nombre",
          title: t("Nombre"),
        },
        {
          name: "regimen",
          title: t("Regimen"),
        },
        {
          name: "fechaConstitucion",
          title: t("Fecha Constitución"),
        },
        {
          name: "estatus",
          title: t("Estatus"),
        },
        {
          name: "created",
          title: t("Created"),
          htmlType: "date",
        },
      ]}
      fields={[]}
    />
  )
}

export default AdminDGIIComponent
