import React, { useEffect, useState } from "react"

import { Col, Row, Container } from "react-bootstrap"

import API from "../../common/API"
import { Routes } from "../../routes"

import { userAvatar } from "../../common/helpers"
import { parsedUser } from "../../common/GetCurrentUser"
import AddEditOrganization from "./AddEditOrganization"
import { useHistory } from "react-router-dom"

//Translation
import { useTranslation } from "react-i18next"
import { confirmAlert } from "react-confirm-alert"

const SelectOrganization = props => {
  const history = useHistory()
  const user = parsedUser()
  const [organizations, setOrganizations] = useState([])
  const [state, setState] = useState({
    showEdit: false,
  })

  const { t } = useTranslation("global")

  useEffect(() => {
    requestOrganizations()
  }, [])

  const requestOrganizations = async () => {
    console.log("render side bar", new Date(), user, user)
    if (user === null) {
      return
    }
    let requestAPI = await API.getAction(
      "Organization/OrganizationsWhereTheUserBelong?AccountId=" + user.ID
    )

    if (requestAPI.data.status === "ok") {
      setOrganizations(requestAPI.data.response)
    }
  }

  const changeTheOrganization = async obj => {
    let theUser = parsedUser()
    let requestAPI = await API.getAction(
      "Organization/SetOrganization?AccountId=" +
        theUser.ID +
        "&OrganizationId=" +
        obj
    )

    if (requestAPI.data.status === "ok") {
      console.log("Organizations", organizations)
      localStorage.setItem(
        "organization",
        JSON.stringify(organizations.filter((org: any) => org.id === obj)[0])
      )

      localStorage.setItem("LogedUser", requestAPI.data.response)
      history.push(Routes.Home.path)
    }
  }
  const deleteRecord = async (item: any) => {
    if (item) {
      confirmAlert({
        closeOnClickOutside: false,
        message: t("Are you sure you want to delete this record?") ?? "",
        buttons: [
          {
            label: t("Yes"),
            onClick: async () => {
              await API.getAction(
                "organization/delete" +
                  "?organizationid=" +
                  item.id +
                  "&userid=" +
                  user.ID
              )
              await requestOrganizations()
            },
          },
          {
            label: "No",
            onClick: () => {
              console.log("no")
            },
          },
        ],
      })
    }
  }
  const toggleAddEdit = async reload => {
    setState({
      ...state,
      showEdit: !state.showEdit,
    })
    if (reload) {
      await requestOrganizations()
    }
  }
  useEffect(() => {
    if (!state.showEdit) {
      requestOrganizations()
    }
  }, [state.showEdit])

  return (
    <main>
      {state.showEdit ? (
        <AddEditOrganization show={state.showEdit} toggle={toggleAddEdit} />
      ) : null}
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            // style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light px-4 px-lg-5 py-3 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h5 className="mb-0">{t("select_organization")}</h5>
                  <hr />
                </div>
                {organizations && organizations.length > 0 ? (
                  organizations.map((item: any, index: number) => (
                    <Row key={index}>
                      <div className="col-auto">
                        <div className="avatar avatar-xl position-relative">
                          {item.name.substring(0, 1)}
                        </div>
                      </div>
                      <Col
                        className="clickable "
                        onClick={() => {
                          changeTheOrganization(item.id)
                        }}
                      >
                        <span>{item.name}</span>
                        <br />
                        <small className="text-muted">
                          {item.creatorName} ({item.creatorEmail})
                        </small>
                      </Col>
                      <Col className="text-end">
                        {user.Email === item.creatorEmail && (
                          <button
                            className="btn btn-link"
                            onClick={() => {
                              deleteRecord(item)
                            }}
                          >
                            <i className="fas fa-trash text-danger"></i>
                          </button>
                        )}
                      </Col>
                      <Col xs={12}>
                        <hr />
                      </Col>
                    </Row>
                  ))
                ) : (
                  <Col
                    xs={12}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span>{t("no_organizations")}</span>
                  </Col>
                )}
                <Row>
                  <Col className="text-center mt-4" xs={12}>
                    <button
                      className="btn btn-primary btn-block"
                      onClick={() => toggleAddEdit(false)}
                    >
                      {t("create_organization")}
                    </button>
                    <br />
                    <button
                      className="btn btn-link"
                      onClick={() => {
                        props.history.push(Routes.SignIn.path)
                      }}
                    >
                      {t("logout")}
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}

export default SelectOrganization
