// db.js
import Dexie from "dexie"
import languages from "./languages"
import { parsedUser } from "./GetCurrentUser"

export const db = new Dexie("data-worker_db")

db.version(1).stores({
  notifications: "++id, status",
  languages: "++id, name, code, select",
  initialPage: "++id, name",
})
db.version(2).stores({
  notifications: "++id, status",
  languages: "++id, name, code, select",
  initialPage: "++id, name",
  settings: "++id",
})

db.version(3).stores({
  notifications: "++id, status",
  languages: "++id, name, code, select",
  initialPage: "++id, name",
  settings: "++id",
  users: "++id",
})

export const defaultDataDB = async () => {
  const user = parsedUser()
  await Dexie.exists("data-worker_db").then(value => {
    if (!value) {
      //Add languages
      languages.map(value => {
        db.languages.add({
          name: value.name,
          code: value.code,
          select: value.select,
        })
      })
      //Add notifications
      db.notifications.add({
        status: "default",
      })

      //Add Settings
      if (user) {
        db.settings.add({
          id: user.ID,
          application: "finance",
          language: "en",
        })
      }
    }
  })
}

export const updateLanguageStatusById = async codeLang => {
  let lang = await db.languages.get({ code: codeLang })

  let distinct = await db.languages
    .filter(value => value.id != lang.id)
    .toArray()

  await distinct.map(value => db.languages.update(value.id, { select: false }))
  await db.languages.update(lang.id, { select: true })
}

export const updateNotificationsStatus = async status =>
  await db.notifications.update(1, { status })

export const getNotificationsStatus = async () => await db.notifications.get(1)

/*const getLanguageUsing = async () => {
  let result = db.languages
    .filter(value => (value.select ? value : ""))
    .toArray()

  let valor = await Promise.resolve(result).then(value => value)
  console.log("RESULT", valor)

  return valor
}*/
