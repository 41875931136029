import React, { useEffect, useState } from "react"
import { parsedUser } from "../../common/GetCurrentUser"
import { Card, Container } from "react-bootstrap"

import GoogleMapReact from "google-map-react"
import PinComponent from "../core/PinComponent"
import Settings from "../../common/Settings"
import API from "../../common/API"
function DashboardContainer() {
  const GoogleMapRef = React.createRef()
  const [zoom, setZoom] = useState(17)
  const [records, setRecords] = useState([])
  const [center, setCenter] = useState({ lat: 18.4718609, lng: -69.8923187 })
  const user = parsedUser()
  const [theMap, setTheMap] = useState<any>(null)
  const [theMapsReference, setTheMapsReference] = useState<any>(null)
  const [avoidAutoZoom, setAvoidAutoZoom] = useState(false)
  const handleApiLoaded = (map, mapsReference) => {
    setTheMap(map)
    setTheMapsReference(mapsReference)
  }
  const onHandleZoom = obj => {
    console.log(obj)
    setZoom(obj.zoom)
  }
  const handleMapClick = e => {
    setCenter({ lat: e.lat, lng: e.lng })
  }

  const bindProjects = async () => {
    try {
      let query = {
        organizationId: user.OrganizationId,
      }
      const request = await API.postAction("projects/list", query)
      if (request.data.status === "ok") {
        setRecords(request.data.response)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const centerMap = () => {
    if (avoidAutoZoom === true) {
      return
    }
    if (theMapsReference) {
      const bounds = new theMapsReference.LatLngBounds()

      records.forEach((marker: any) => {
        if (marker.latitude) {
          bounds.extend(
            new theMapsReference.LatLng(marker.latitude, marker.longitude)
          )
        }
      })
      if (theMap) {
        theMap.fitBounds(bounds)
      }
      //theMap.setZoom(zoom);
    }
  }

  useEffect(() => {
    bindProjects()
  }, [])

  useEffect(() => {
    centerMap()
  }, [records])
  return (
    <Container fluid>
      <Card className="shadow-lg">
        <Card.Body className="p-0">
          <GoogleMapReact
            ref={GoogleMapRef}
            // onClick={this.handleClick}
            onClick={handleMapClick}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            bootstrapURLKeys={{ key: Settings.GoogleMapsAPIKey }}
            style={{
              width: "100%",
              minHeight: "80vh",
              // height: "100vh",
              position: "relative",
            }}
            //id={this.props.Element.nombreCampo}
            center={{ lat: center.lat, lng: center.lng }}
            defaultZoom={zoom}
            onChange={e => onHandleZoom(e)}
          >
            {records.map((marker: any) => (
              <PinComponent
                key={marker.id}
                lat={marker.latitude}
                lng={marker.longitude}
                title={marker.name}
              />
            ))}
          </GoogleMapReact>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default DashboardContainer
