import React, { useEffect, useState } from "react"
import { Card, Container } from "react-bootstrap"
import ActionBar from "../core/ActionBar"
import Pagination from "../core/Pagination"
import AddEdit from "./AddEdit"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import { useTranslation } from "react-i18next"
import { confirmAlert } from "react-confirm-alert"

function ProjectsContainer() {
  const [records, setRecords] = useState([])
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showAddEdit, setShowAddEdit] = useState(false)
  const user = parsedUser()
  const { t } = useTranslation("global")
  const toggleAddEdit = (item?: any) => {
    setShowAddEdit(!showAddEdit)
    setSelectedItem(item)

    if (showAddEdit) {
      bindDataRemote()
    }
  }

  const deleteRecord = async (item: any) => {
    if (item) {
      confirmAlert({
        closeOnClickOutside: false,
        message: t("Are you sure you want to delete this record?") ?? "",
        buttons: [
          {
            label: t("Yes"),
            onClick: async () => {
              await API.getAction("projects/delete?id=" + item.id)
              await bindDataRemote()
              setSelectedItem(null)
            },
          },
          {
            label: "No",
            onClick: () => {
              console.log("no")
            },
          },
        ],
      })
    }
  }

  const bindDataRemote = async () => {
    try {
      console.log("bindDataRemote")
      setIsLoading(true)
      let query = {
        organizationId: user.OrganizationId,
      }
      const request = await API.postAction("projects/list", query)
      if (request.data.status === "ok") {
        setRecords(request.data.response)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    bindDataRemote()
  }, [])
  return (
    <>
      {showAddEdit && (
        <AddEdit
          show={showAddEdit}
          toggle={toggleAddEdit}
          record={selectedItem}
        />
      )}
      <Container>
        <ActionBar
          title="Proyectos"
          actions={
            <>
              <button
                className="btn btn-primary btn-sm me-2"
                type="button"
                onClick={() => toggleAddEdit()}
              >
                <i className="fa fa-plus " />
              </button>
            </>
          }
        />
        <Card className="my-4">
          <Card.Body>
            {records && records.length > 0 && (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{t("Name")}</th>
                    <th>{t("Notes")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((item: any, index: number) => {
                    console.log(item)
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.notes}</td>
                        <td className="text-end">
                          <div className="btn-group">
                            <button
                              className="btn btn-sm "
                              type="button"
                              onClick={() => toggleAddEdit(item)}
                            >
                              <i className="fa fa-edit" />
                            </button>
                            <button
                              className="btn btn-sm"
                              type="button"
                              onClick={() => {
                                deleteRecord(item)
                              }}
                            >
                              <i className="fa fa-trash text-danger" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </Card.Body>
        </Card>
        <Pagination
          totalPages={1}
          currentPage={0}
          onPageChanged={() => {
            console.log("onPageChanged")
          }}
        />
      </Container>
    </>
  )
}

export default ProjectsContainer
