import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Settings from "../../../common/Settings"

const VerificarAntecedentesCardView = ({ records, isLoading }) => {
  const { t } = useTranslation("global")
  return (
    <Row>
      {records &&
        records.map((item: any, index: number) => {
          return (
            <Col xs={12} md={4} lg={4} key={index}>
              <Card className="mb-4">
                <Card.Body className="p-0 ">
                  {item.imagen && (
                    <img
                      alt=""
                      className="card-img-top"
                      // style={{ maxHeight: "175px" }}
                      id="image"
                      src={"data:image/png;base64," + item.imagen}
                    />
                  )}
                  <p className="text-center my-2 card-text text-uppercase">
                    {`${item.nombres} ${item.apellido1} ${item.apellido2}`}
                    <br />
                    <b>{item.cedula}</b>
                  </p>
                </Card.Body>
                <Card.Footer className="text-center ">
                  {item.fichas === 0 && (
                    <div
                      className="badge bg-success text-wrap"
                      title={"No antencedentes judiciales"}
                    >
                      <i className="fa fa-check"></i>
                      <span className="ms-2">No antencedentes judiciales</span>
                    </div>
                  )}
                  {item.fichas > 1 && (
                    <div
                      className="badge bg-danger  text-wrap"
                      title={
                        "Solicitar certificado de no antecedentes judiciales"
                      }
                    >
                      <i className="fa fa-exclamation-triangle"></i>
                      <span className="ms-2">
                        Solicitar certificado de no antencedentes judiciales
                      </span>
                    </div>
                  )}
                  {item.fichas == 1 && (
                    <div
                      className="badge bg-warning  text-wrap"
                      title={
                        "Solicitar certificado de no antecedentes judiciales"
                      }
                    >
                      <i className="fa fa-exclamation-triangle"></i>
                      <span className="ms-2">
                        Solicitar certificado de no antencedentes judiciales
                      </span>
                    </div>
                  )}

                  <div
                    className="badge bg-warning  text-wrap"
                    title={"No casos laborales"}
                  >
                    <i className="fa fa-check"></i>
                    <span className="ms-2">No casos laborales</span>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          )
        })}
    </Row>
  )
}

export default VerificarAntecedentesCardView
