import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const ComplainCategoryComponent = (props: any) => {
  const [t] = useTranslation("global")

  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("Complain Category") ?? ""}
      getRecordsMethod="post"
      getRecordsPath={`ComplainCategory/list`}
      addEditRecordPath={`ComplainCategory/AddEdit`}
      deleteRecordPath="ComplainCategory/Delete"
      fieldsList={[
        {
          name: "name",
          title: t("name"),
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
      ]}
    />
  )
}

export default ComplainCategoryComponent
