import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Settings from "../../../common/Settings"

const ForeignersVerificarAntecedentesCardView = ({ records, isLoading }) => {
  const { t } = useTranslation("global")
  return (
    <Row>
      {records &&
        records.map((item: any, index: number) => {
          return (
            <Col xs={12} md={4} lg={4} key={index}>
              <Card className="mb-4">
                <Card.Body className="p-0 ">
                  <p className="text-center my-4 ">
                    <i className="fas fa-3x fa-user m-4  "></i>
                  </p>
                  {/* {item.imagen && (
                    <img
                      alt=""
                      className="card-img-top"
                      // style={{ maxHeight: "175px" }}
                      id="image"
                      src={"data:image/png;base64," + item.imagen}
                    />
                  )} */}
                  <p className="text-center my-2 card-text text-uppercase">
                    {`${item.nombre} ${item.apellidos}`}
                    <br />

                    <small>{item.alias}</small>
                    <br />
                    <b>{item.cedula}</b>
                  </p>
                </Card.Body>
                <Card.Footer className="text-center ">
                  {item.observaciones.length > 0 && (
                    <span
                      className="badge bg-danger"
                      title={
                        "Solicitar certificado de no antecedentes judiciales"
                      }
                    >
                      <i className="fa fa-exclamation-triangle me-2"></i>
                      Solicitar certificado de no antecedentes judiciales
                    </span>
                  )}
                  <div
                    className="badge bg-warning  text-wrap"
                    title={"No casos laborales"}
                  >
                    <i className="fa fa-check"></i>
                    <span className="ms-2">No casos laborales</span>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          )
        })}
    </Row>
  )
}

export default ForeignersVerificarAntecedentesCardView
