import React, { useEffect, useState } from "react"
import { Card, Container, Row } from "react-bootstrap"
import ActionBar from "../core/ActionBar"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import Filter from "../core/Filter/Filter"
import { useTranslation } from "react-i18next"
import ComplainAddEdit from "./ComplainAddEdit"
import moment from "moment"
import Pagination from "../core/Pagination"
import { confirmAlert } from "react-confirm-alert"

function ComplainContainer() {
  const [showFilter, setShowFilter] = useState(true)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [filterFields, setFilterFields] = useState([
    {
      name: "typeOfLocationId",
      title: "Type of location",
      htmlType: "select",
      dbType: "string",

      containerClass: "col-md-6 col-12",
      datasourceMethod: "POST",
      dataSource: "typeOfLocation/list",
      dataSourceTextField: "name",
      dataSourceValueField: "id",
    },
    {
      name: "complainCategoryId",
      title: "Complain type",
      htmlType: "select",
      dbType: "string",

      containerClass: "col-md-6 col-12",
      datasourceMethod: "POST",
      dataSource: "complainCategory/list",
      dataSourceTextField: "name",
      dataSourceValueField: "id",
    },
  ])
  const [showAddEdit, setShowAddEdit] = useState(false)
  const { t } = useTranslation("global")
  const [isLoading, setIsLoading] = useState(false)
  const [records, setRecords] = useState<any>([])
  const user = parsedUser()
  const [filterObj, setFilterObj] = useState({})

  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }
  const onPageChanged = (obj: number) => {
    setPage(obj)
  }
  const toggleFilter = () => setShowFilter(!showFilter)
  const bindDataRemote = async () => {
    try {
      let filterObj = {
        page: 0,
        quantity: 10,
        organizationId: user.OrganizationId,
      }
      setIsLoading(true)
      let request = await API.postAction("complain/list", filterObj)

      setRecords(request.data.response)
      console.log("request remote")
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }
  const toggleAddEdit = async (item?: any) => {
    setSelectedItem(item)
    setShowAddEdit(!showAddEdit)
    if (showAddEdit) {
      await bindDataRemote()
    }
  }

  const deleteRecord = async (item: any) => {
    if (item) {
      confirmAlert({
        closeOnClickOutside: false,
        message: t("Are you sure you want to delete this record?") ?? "",
        buttons: [
          {
            label: t("Yes"),
            onClick: async () => {
              await API.getAction("complain/delete?id=" + item.id)
              await bindDataRemote()
              setSelectedItem(null)
            },
          },
          {
            label: "No",
            onClick: () => {
              console.log("no")
            },
          },
        ],
      })
    }
  }

  useEffect(() => {
    bindDataRemote()
  }, [])

  return (
    <>
      {showAddEdit && (
        <ComplainAddEdit
          show={showAddEdit}
          toggle={toggleAddEdit}
          cssClass="modalRight"
          model={selectedItem}
        />
      )}
      <Container>
        <ActionBar
          title={t("Complains")}
          actions={
            <>
              <button
                className={
                  "btn  btn-sm  " + (showFilter ? "btn-secondary" : "")
                }
                onClick={() => toggleFilter()}
              >
                <i className="fa fa-filter" />
              </button>
              <button
                className={"ms-2 btn btn-sm btn-primary  "}
                onClick={() => toggleAddEdit()}
              >
                <i className="fa fa-plus " />
              </button>
            </>
          }
        />
        {showFilter && (
          <div className="my-4">
            <Filter
              title={t("Filter")}
              toggleFilter={toggleFilter}
              fields={filterFields}
              filterAction={() => {
                bindDataRemote()
              }}
              setFilterFields={getFilterValues}
            />
          </div>
        )}

        <Card className="p-0">
          <Card.Body className="p-0">
            <div className="table-responsive">
              {records && records.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>{t("Creation")}</th>
                      <th>{t("When it happen")}</th>
                      <th>{t("Category")}</th>
                      <th>{t("Notes")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {records &&
                      records.map((item: any, index: number) => {
                        console.log("con", item)
                        return (
                          <tr key={index}>
                            <td>{moment(item.created).fromNow()}</td>
                            <td>
                              {moment(item.whenItHappen).format(
                                "MMM DD, YYYY hh:mm"
                              )}
                            </td>
                            <td>{item.complainCategoryName}</td>
                            <td>{item.otherDetails ?? ""}</td>
                            <td className="text-end">
                              <div className="btn-group">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary"
                                  onClick={() => {
                                    toggleAddEdit(item)
                                  }}
                                >
                                  <i className="fa fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary"
                                  onClick={() => {
                                    deleteRecord(item)
                                  }}
                                >
                                  <i className="fa fa-trash text-danger"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              ) : (
                <h3>{t("No record")}</h3>
              )}
            </div>
          </Card.Body>
        </Card>

        <Card className="my-4">
          <Card.Body className="p-0 pt-2">
            <Pagination
              totalPages={totalPages}
              currentPage={page}
              onPageChanged={onPageChanged}
            />
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}

export default ComplainContainer
