export default [
  {
    name: "firstName",
    htmlType: "text",
    dbType: "string",
    title: "Nombre",
    containerClass: "col-12 col-md-4",
    required: false,
  },
  {
    name: "lastName",
    htmlType: "text",
    dbType: "string",
    title: "Apellido",
    containerClass: "col-12 col-md-4",
    required: false,
  },
  {
    name: "secondLastName",
    htmlType: "text",
    dbType: "string",
    title: "Segundo Apellido",
    containerClass: "col-12 col-md-4",
    required: false,
  },
  {
    name: "alias",
    htmlType: "text",
    dbType: "string",
    title: "Alias",
    containerClass: "col-12 col-md-4",
    required: false,
  },
  // {
  //   name: "tss",
  //   htmlType: "text",
  //   dbType: "string",
  //   title: "TSS",
  //   containerClass: "col-12 col-md-4",
  //   required: false,
  // },
  {
    name: "cedula",
    htmlType: "text",
    dbType: "string",
    title: "cedula",
    containerClass: "col-12 col-md-4",
    required: false,
  },
  // {
  //   name: "passport",
  //   htmlType: "text",
  //   dbType: "string",
  //   title: "Passport",
  //   containerClass: "col-12 col-md-4",
  //   required: false,
  // },
]
