import { Routes } from "../../../routes"

export const SidebarAdmin = () => {
  let results = {
    title: "admin",
    items: [
      {
        title: "users",
        icon: "fa fa-users",
        link: Routes.AdminUsers.path,
      },
      {
        title: "organizations",
        icon: "fa fa-sitemap",
        link: Routes.AdminOrganizations.path,
      },

      // {
      //   title: "reports",
      //   icon: "fa fa-chart-line",
      //   link: Routes.AdminReports.path,
      // },
    ],
  }
  return results
}

export const SidebarRegular = () => {
  let results = {
    title: "dataworker",
    items: [
      {
        title: "Verificar Postulantes",
        icon: "fa fa-search",
        link: Routes.CheckProfile.path,
      },
      {
        title: "Plantilla de Personal",
        icon: "fa fa-folder",
        link: Routes.Home.path,
      },
      {
        title: "Proyectos",
        icon: "fa fa-briefcase",
        link: Routes.Projects.path,
      },
      {
        title: "Dashboard",
        icon: "fa fa-chart-line",
        link: Routes.Dashboard.path,
      },
      // {
      //   title: "Reportes",
      //   icon: "fa fa-exclamation",
      //   link: "#reports",
      //   subItems: [
      //     {
      //       title: "Incidentes",
      //       icon: "fa fa-circle-exclamation",
      //       link: Routes.Incidents.path,
      //     },
      //     {
      //       title: "Alertas de personal",
      //       icon: "fa fa-triangle-exclamation",
      //       link: Routes.Alerts.path,
      //     },
      //   ],
      // },
      {
        title: "Denuncias",
        icon: "fa fa-file-pen",
        link: Routes.Complains.path,
      },

      // {
      //   title: "reports",
      //   icon: "fa fa-chart-line",
      //   link: Routes.Reports.path,
      // },

      // {
      //   title: "settings",
      //   icon: "fa fa-cog",
      //   link: Routes.Settings.path,
      // },
    ],
  }
  return results
}
