import CRUDField from "../core/genericCRUD/CRUDField"

const EmployeDTO: CRUDField[] = [
  {
    name: "separatorGeneral",
    title: "Información Personal",
    htmlType: "separator",
    dbType: "string",
    required: false,

    containerClass: "col-12",
    groupNumber: 0,
  },
  {
    name: "id",
    title: "id",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "employeeId",
    title: "employeeId",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "organizationId",
    title: "organizationId",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },

  {
    name: "identifierPicture",
    title: "Identifier Picture",
    htmlType: "picture",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  {
    name: "identifier",
    title: "Cédula",
    htmlType: "cedula",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },

  {
    name: "passport",
    title: "Passport",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  {
    name: "noTSS",
    title: "Número de TSS",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  {
    name: "employeeTypeId",
    title: "Employee Type",
    htmlType: "select",
    dbType: "string",
    required: true,
    containerClass: "col-md-6 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "employeeType/list",
    datasourceMethod: "POST",
    groupNumber: 0,
  },
  {
    name: "firstName",
    title: "Names",
    htmlType: "text",
    dbType: "string",
    required: true,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  // {
  //   name: "middleName",
  //   title: "Middle Name",
  //   htmlType: "text",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-6 col-12",
  //   groupNumber: 0,
  // },

  {
    name: "lastName",
    title: "Apellido 1",
    htmlType: "text",
    dbType: "string",
    required: true,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  {
    name: "secondLastName",
    title: "Apellido 2",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },

  {
    name: "birthdate",
    title: "Birthdate",
    htmlType: "date",
    dbType: "datetime",
    required: true,
    containerClass: "col-md-6 col-12",
    groupNumber: 0,
  },
  {
    name: "genderId",
    title: "Gender",
    htmlType: "select",
    dbType: "string",
    required: true,
    containerClass: "col-md-6 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "gender/list",
    datasourceMethod: "POST",
    groupNumber: 0,
  },
  {
    name: "birthPlaceId",
    title: "Birth Place",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "country/list",
    datasourceMethod: "POST",
    groupNumber: 0,
  },
  {
    name: "civilStatudId",
    title: "Civil Status",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "civilStatus/list",
    datasourceMethod: "POST",
    groupNumber: 0,
  },
  {
    name: "socialGroupId",
    title: "Social Group",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "socialGroup/list",
    datasourceMethod: "POST",
    groupNumber: 0,
  },
  {
    name: "occupationId",
    title: "Occupation",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "occupation/list",
    datasourceMethod: "POST",
    groupNumber: 0,
  },
  {
    name: "nationalityId",
    title: "Nationality",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "nationality/list",
    datasourceMethod: "POST",
    groupNumber: 0,
  },

  {
    name: "phone",
    title: "Phone",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 1,
  },
  {
    name: "phone2",
    title: "Phone 2",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 1,
  },
  // {
  //   name: "alias",
  //   title: "Alias",
  //   htmlType: "text",
  //   dbType: "string",
  //   required: false,
  //   containerClass: "col-md-6 col-12",
  //   groupNumber: 1,
  // },
  {
    name: "separatorApodos",
    title: "Apodos Reconocidos",
    htmlType: "separator",
    dbType: "string",
    required: false,

    containerClass: "col-12",
    groupNumber: 1,
  },
  {
    name: "alias",
    title: "Alias",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 1,
  },
  {
    name: "alias2",
    title: "Alias 2",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 1,
  },
  //Datos de los Padres y Conyuge
  {
    name: "separator",
    title: "Datos de los Padres y Conyuge",
    htmlType: "separator",
    dbType: "string",
    required: false,

    containerClass: "col-12",
    groupNumber: 2,
  },
  // caracteristicas fisicas
  {
    name: "separator",
    title: "Características Fisicas",
    htmlType: "separator",
    dbType: "string",
    required: false,
    groupNumber: 3,
    containerClass: "col-12",
  },
  {
    name: "weightId",
    title: "Weight",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "weight/list",
    datasourceMethod: "POST",
    groupNumber: 3,
  },
  {
    name: "heightId",
    title: "Height",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "height/list",
    datasourceMethod: "POST",
    groupNumber: 3,
  },
  {
    name: "skinColorId",
    title: "Skin Color",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "skinColor/list",
    datasourceMethod: "POST",
    groupNumber: 3,
  },
  {
    name: "physicalBuildId",
    title: "Physical Build",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "physicalBuild/list",
    datasourceMethod: "POST",
    groupNumber: 3,
  },
  //   Facial Characteristics
  {
    name: "separator",
    title: "Características del rostro",
    htmlType: "separator",
    dbType: "string",
    required: false,

    groupNumber: 4,
    containerClass: "col-12",
  },
  {
    name: "hairTypeId",
    title: "Hair",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",

    dataSource: "hairType/list",
    datasourceMethod: "POST",
    groupNumber: 4,
  },
  {
    name: "eyeTypeId",
    title: "Eyes",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "eyeType/list",
    datasourceMethod: "POST",
    groupNumber: 4,
  },
  {
    name: "noseTypeId",
    title: "Nose",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "noseType/list",
    datasourceMethod: "POST",
    groupNumber: 4,
  },
  {
    name: "mouthTypeId",
    title: "Mouth",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "mouthType/list",
    datasourceMethod: "POST",
    groupNumber: 4,
  },
  {
    name: "earTypeId",
    title: "Ears",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "earType/list",
    datasourceMethod: "POST",
    groupNumber: 4,
  },
  {
    name: "expresionLineId",
    title: "Expresion Lines",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "expressionLine/list",
    datasourceMethod: "POST",
    groupNumber: 4,
  },
  {
    name: "faceId",
    title: "Face",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "face/list",
    datasourceMethod: "POST",
    groupNumber: 4,
  },

  //   Rasgos Particulares
  {
    name: "separator",
    title: "Características particulares",
    htmlType: "separator",
    dbType: "string",
    required: false,
    groupNumber: 5,
    containerClass: "col-12",
  },
  {
    name: "tattooPlaceId",
    title: "Tattoo Place",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "tattooPlace/list",
    datasourceMethod: "POST",
    groupNumber: 5,
  },
  {
    name: "tattooDescription",
    title: "Tattoo description",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    groupNumber: 5,
  },
  {
    name: "tattooLettersAndNumber",
    title: "Tattoo Letters and Number",
    htmlType: "text",
    dbType: "string",
    required: false,
    containerClass: "col-md-6 col-12",
    groupNumber: 5,
  },
  {
    name: "scarPlaceId",
    title: "Scar Place",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "scarPlace/list",
    datasourceMethod: "POST",
    groupNumber: 5,
  },
  {
    name: "stainsId",
    title: "Stains",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "stain/list",
    datasourceMethod: "POST",
    groupNumber: 5,
  },
  {
    name: "burnPlaceId",
    title: "Burn Place",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "burnPlace/list",
    datasourceMethod: "POST",
    groupNumber: 5,
  },
  {
    name: "voiceQualityId",
    title: "Voice Quality",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "voiceQuality/list",
    datasourceMethod: "POST",
    groupNumber: 5,
  },
  {
    name: "discapacityId",
    title: "Discapacity",
    htmlType: "select",
    dbType: "string",
    required: false,
    containerClass: "col-md-3 col-12",
    dataSourceTextField: "name",
    dataSourceValueField: "id",
    dataSource: "discapacity/list",
    datasourceMethod: "POST",
    groupNumber: 5,
  },
  {
    name: "otherParticularity",
    title: "Other Particularities",
    htmlType: "textarea",
    dbType: "string",
    required: false,
    containerClass: "col-12",
    groupNumber: 5,
  },
  {
    name: "photos",
    title: "Fotos",
    htmlType: "separator",
    dbType: "string",
    required: false,
    containerClass: "col-12",
    groupNumber: 6,
  },
  {
    name: "recommendations",
    title: "Recommendations",
    htmlType: "textarea",
    dbType: "string",
    required: false,
    containerClass: "col-12",
    groupNumber: 7,
  },
  // status y Antecedentes Judiciales
  {
    name: "status",
    title: "Estatus y Antecedentes Judiciales",
    htmlType: "separator",
    dbType: "string",
    required: false,
    containerClass: "col-12",
    groupNumber: 8,
  },

  {
    name: "sanctionsAdminTribunal",
    title: "Tribunal Administrativo",
    htmlType: "textarea",
    dbType: "string",
    required: false,
    containerClass: "col-12 col-md-6",
    groupNumber: 8,
  },
  {
    name: "sanctionsLaborTribunal",
    title: "Tribunal Laboral",
    htmlType: "textarea",
    dbType: "string",
    required: false,
    containerClass: "col-12 col-md-6",
    groupNumber: 8,
  },
  {
    name: "sanctionsPenalTribunal",
    title: "Tribunal Penal",
    htmlType: "textarea",
    dbType: "string",
    required: false,
    containerClass: "col-12 col-md-6",
    groupNumber: 8,
  },
  {
    name: "sanctionsOther",
    title: "Otras sanciones",
    htmlType: "textarea",
    dbType: "string",
    required: false,
    containerClass: "col-12 col-md-6",
    groupNumber: 8,
  },

  // historico de demandas laborales

  {
    name: "status",
    title: "Historico de demandas laborales",
    htmlType: "separator",
    dbType: "string",
    required: false,
    containerClass: "col-12",
    groupNumber: 8,
  },

  {
    name: "laborDemandPlaintiff",
    title: "Querellante",
    htmlType: "textarea",
    dbType: "string",
    required: false,
    containerClass: "col-12 col-md-6",
    groupNumber: 8,
  },
  {
    name: "laborDemandDefendant",
    title: "Demandado",
    htmlType: "textarea",
    dbType: "string",
    required: false,
    containerClass: "col-12 col-md-6",
    groupNumber: 8,
  },

  {
    name: "biometrical",
    title: "Captura Biométrica",
    htmlType: "separator",
    dbType: "string",
    required: false,
    containerClass: "col-12",
    groupNumber: 9,
  },
]

export default EmployeDTO
