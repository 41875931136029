import React, { useState } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Routes } from "../../routes"
import AdminUsersComponent from "./AdminUsersComponent"
import Settings from "../Settings/Settings"
import AdminOrganizationsComponent from "./AdminOrganizationsComponent"
import AdminExtranjerosHaitianosComponent from "./AdminExtranjerosHaitianosComponent"
import AdminFichadosComponent from "./AdminFichadosComponent"
import AdminDGIIComponent from "./AdminDGIIComponent"
import AdminCentrosDetenidos from "./AdminCentrosDetenidos"
import AdminDetenidos from "./AdminDetenidos"

function AdminContainer() {
  const { t } = useTranslation("global")
  const [section, setSection] = useState<string>("users")
  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={3} lg={2}>
          <div className="my-4 bg-white shadow-sm border-end px-2 py-2">
            <h5>Menu</h5>
            <hr className="text-light bg-light" />
            <ul className="list-unstyled">
              <li className="clickable  border-bottom py-2 px-2">Dashboard</li>
              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "dgii" ? "active" : "")
                }
                onClick={() => setSection("dgii")}
              >
                DGII RNC
              </li>
              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "detenidos" ? "active" : "")
                }
                onClick={() => setSection("detenidos")}
              >
                Detenidos
              </li>
              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "centrosDetenidos" ? "active" : "")
                }
                onClick={() => setSection("centrosDetenidos")}
              >
                Centros - Detenidos
              </li>
              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "fichados" ? "active" : "")
                }
                onClick={() => setSection("fichados")}
              >
                Fichados
              </li>
              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "extranjerosHaitianos" ? "active" : "")
                }
                onClick={() => setSection("extranjerosHaitianos")}
              >
                Extranjeros Haitianos
              </li>
              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "companies" ? "active" : "")
                }
                onClick={() => setSection("companies")}
              >
                {t("Organizations")}
              </li>

              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "users" ? "active" : "")
                }
                onClick={() => setSection("users")}
              >
                {t("Users")}
              </li>
              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "settings" ? "active" : "")
                }
                onClick={() => setSection("settings")}
              >
                {t("Settings")}
              </li>
            </ul>
          </div>
        </Col>
        <Col xs={12} md={9} lg={10}>
          <div className="mt-4" />
          {section === "users" && <AdminUsersComponent />}
          {section === "extranjerosHaitianos" && (
            <AdminExtranjerosHaitianosComponent />
          )}
          {section === "dgii" && <AdminDGIIComponent />}
          {section === "detenidos" && <AdminDetenidos />}
          {section === "centrosDetenidos" && <AdminCentrosDetenidos />}
          {section === "fichados" && <AdminFichadosComponent />}
          {section === "companies" && <AdminOrganizationsComponent />}
          {section === "settings" && <Settings />}
        </Col>
      </Row>
    </Container>
  )
}

export default AdminContainer
