import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import ActionBar from "../core/ActionBar"
import Filter from "../core/Filter/Filter"
import { useTranslation } from "react-i18next"
import HomeFilterFields from "./HomeFilter.fields"
import EmployeAddEdit from "../Employees/EmployeAddEdit"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"

import { confirmAlert } from "react-confirm-alert"
import Pagination from "../core/Pagination"
import HomeComponentListView from "./HomeComponentListView"
import HomeComponentCardView from "./HomeComponentCardView"
import VerificarAntecedentes from "../CheckProfile/VerificarAntecedentes"

const HomeComponent = props => {
  // PROPERTIES
  const [viewMode, setViewMode] = useState<"list" | "grid">("list")
  const [currentOrganization, setCurrentOrganization] = useState<any>(null)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [showAntecedentes, setShowAntecedentes] = useState<boolean>(false)
  const [readOnly, setReadOnly] = useState<boolean>(false)
  const [showAddEdit, setShowAddEdit] = useState(false)
  const user = parsedUser()
  const [isLoading, setIsLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(true)
  const toggleFilter = () => setShowFilter(!showFilter)
  const [filterObj, setFilterObj] = useState({})
  const { t } = useTranslation("global")
  const filterFields = HomeFilterFields
  const [records, setRecords] = useState([])
  const [record, setRecord] = useState(null)
  //   METHODS
  const toggleAddEdit = async (obj: any, readonly?: boolean | undefined) => {
    setShowAddEdit(!showAddEdit)
    if (readonly) {
      setReadOnly(true)
    }
    setRecord(obj)
    if (showAddEdit) {
      await getRecords()
      setReadOnly(false)
    }
    console.log(obj)
  }
  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }
  const deleteRecord = async (item: any) => {
    if (item) {
      confirmAlert({
        closeOnClickOutside: false,
        message: t("Are you sure you want to delete this record?") ?? "",
        buttons: [
          {
            label: t("Yes"),
            onClick: async () => {
              await API.getAction("Employee/delete" + "?id=" + item.employeeId)
              await getRecords()
              setRecord(null)
            },
          },
          {
            label: "No",
            onClick: () => {
              console.log("no")
            },
          },
        ],
      })
    }
  }
  const getRecords = async () => {
    try {
      let filterObj = {
        page: 0,
        quantity: 10,
        organizationId: user.OrganizationId,
      }
      setIsLoading(true)
      let request = await API.postAction("Employee/list", filterObj)

      setRecords(request.data.response)
      console.log("request remote")
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }
  // EVENTS

  const onPageChanged = (obj: number) => {
    setPage(obj)
  }
  useEffect(() => {
    getRecords()
  }, [page])

  useEffect(() => {
    console.log("users", user)
    getRecords()
    let currntOrg = localStorage.getItem("organization")
    if (currntOrg) {
      setCurrentOrganization(JSON.parse(currntOrg))
    }
  }, [])
  useEffect(() => {
    console.log("showAntecedentes", showAntecedentes)
  }, [showAntecedentes])

  return (
    <Container>
      {showAntecedentes && (
        <VerificarAntecedentes
          toggle={() => setShowAntecedentes(!showAntecedentes)}
          modalSize="md"
          title={t("Antecedentes")}
        />
      )}
      {showAddEdit && (
        <EmployeAddEdit
          toggle={toggleAddEdit}
          title={t("Employee")}
          cssClass="modalRight"
          record={record}
          readOnly={readOnly}
        />
      )}
      <ActionBar
        title={currentOrganization?.name}
        actions={
          <>
            <button
              className={"btn  btn-sm  " + (showFilter ? "btn-secondary" : "")}
              onClick={() => toggleFilter()}
            >
              <i className="fa fa-filter" />
            </button>

            <div className="ms-2 btn-group">
              <button
                onClick={() => setViewMode("grid")}
                className={
                  "btn btn-sm " +
                  (viewMode === "grid" ? "btn-primary" : "btn-secondary")
                }
              >
                Tarjeta
              </button>
              <button
                onClick={() => setViewMode("list")}
                className={
                  "btn btn-sm " +
                  (viewMode === "list" ? "btn-primary" : "btn-secondary")
                }
              >
                Lista
              </button>
            </div>
          </>
        }
      ></ActionBar>

      {showFilter && (
        <div className="my-4">
          <Filter
            title={t("Plantilla de empleados")}
            toggleFilter={toggleFilter}
            fields={filterFields}
            filterAction={() => {
              getRecords()
            }}
            setFilterFields={getFilterValues}
          />
        </div>
      )}
      <Row>
        {viewMode === "list" ? (
          <HomeComponentListView
            records={records}
            toggleAddEdit={toggleAddEdit}
            deleteRecord={deleteRecord}
          />
        ) : viewMode === "grid" ? (
          <HomeComponentCardView
            records={records}
            toggleAddEdit={toggleAddEdit}
            deleteRecord={deleteRecord}
          />
        ) : null}
        <Col xs={12} className="text-end my-4">
          <button
            className={"ms-2 btn d-xs-none  btn-primary  "}
            onClick={() => toggleAddEdit(null)}
          >
            <i className="fa fa-plus me-2" />
            {t("Add Employee")}
          </button>
        </Col>
        <Col xs={12}>
          <Card className="mb-4">
            <Card.Body className="p-0 pt-2">
              <Pagination
                totalPages={totalPages}
                currentPage={page}
                onPageChanged={onPageChanged}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default HomeComponent
