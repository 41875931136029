import { useFormik } from "formik"
import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import * as yup from "yup"

import yupTypeValidator from "../../../common/YupTypeValidator"

import FieldSelector from "../FieldSelector"

import { confirmAlert } from "react-confirm-alert" // Import
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import CRUDField from "./CRUDField"
import API from "../../../common/API"
import { Card, Container, Modal } from "react-bootstrap"
import CRUDProps from "./CRUDProps"
import Sha256 from "../../../common/sha256"
import ActionBar from "../ActionBar"
import Pagination from "../Pagination"
import moment from "moment"
import Filter from "../Filter/Filter"
import Settings from "../../../common/Settings"

type YupSchema =
  | yup.StringSchema
  | yup.BooleanSchema
  | yup.NumberSchema
  | yup.DateSchema
//multas, condenas, condicion juridica, edad, , centro, ficha
// ficha cada vez que entre
const CRUD = (props: CRUDProps) => {
  // PROPERTIES
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(props.pageSize ?? 10)
  const [totalPages, setTotalPages] = useState(1)
  const { t } = useTranslation("global")
  const [record, setRecord] = useState(null)
  const [records, setRecords] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formikInitialObject, setFormikInitialObject] = useState<any>({})
  const fields = props.fields
  const fieldsList = props.fieldsList
  const [filterObj, setFilterObj] = useState({})
  const [showAddEdit, setShowAddEdit] = useState(false)
  const [showFilter, setShowFilter] = useState(props.filterOpen ?? false)
  const [validationsSchema, setValidationSchema] =
    useState<yup.ObjectSchema<any>>()
  const formik = useFormik({
    initialValues: formikInitialObject,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: () => addEditRecord(),
  })
  // METHODS
  const fillFormikObject = (record: any) => {
    fields.forEach((item: CRUDField) => {
      if (item && item.name.length > 0) {
        //console.log("field name", item.name, "RecordValue ", record[item.name]);
        if (record && record[item.name]) {
          formik.setFieldValue(item.name, record[item.name])
        }
      }
    })
  }

  const resultadosCedula = async (value: any) => {
    // formik.setFieldValue("cedula", value)
    let item = value[0]
    if (item) {
      if (props.id === "contractors") {
        formik.setFieldValue(
          "representative",
          item.nombres + " " + item.apellido1 + " " + item.apellido2
        )
      }
      // if (item.imagen) {
      //   let file = Base64ToFile(
      //     "data:image/jpeg;base64," + item.imagen,
      //     item.cedula + ".jpg"
      //   )
      //   let bodyData = new FormData()
      //   bodyData.append("path", file)
      //   const request = await API.postAction(`File/Upload`, bodyData, {
      //     headers: { "Content-Type": "multipart/form-data" },
      //   })

      //   formik.setFieldValue("identifierPicture", request.data.response)
      //   // let bodyData = new FormData()
      //   // bodyData.append("path", file)
      //   // const request = await API.postAction(`File/Upload`, bodyData, {
      //   //   headers: { "Content-Type": "multipart/form-data" },
      //   // })
      //   // if (field.includes("photo")) setPhoto(request.data.response)
      //   // props.updateField(model.name, request.data.response)
      // }
      // formik.setFieldValue("phone", item.phone)
      // formik.setFieldValue("position", item.position)
    }
    console.log("relatives", value)
  }

  const resultadosRnc = async (value: any) => {
    // formik.setFieldValue("cedula", value)
    let item = value[0]
    if (item) {
      formik.setFieldValue("companyName", item.nombre)
    }
    console.log("relatives", value)
  }
  const prepareFormikObject = () => {
    const initialObject: { [key: string]: string } = {}
    const initialObjectValidation: { [key: string]: YupSchema } = {}

    if (fields) {
      fields.forEach((item: CRUDField) => {
        // if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.name] = item.value

        if (item.required) {
          const schema = yupTypeValidator(item)
          if (schema) {
            initialObjectValidation[item.name] = schema
          }
        }
      })
    }

    // formik.initialValues = initialObject;
    setFormikInitialObject(initialObject)
    setValidationSchema(yup.object().shape(initialObjectValidation))
  }

  const getRecords = async () => {
    try {
      setIsLoading(true)
      // POST METHOD
      if (props.getRecordsMethod === "post") {
        let query: any = {}
        query.page = page
        query.quantity = pageSize
        console.log(filterObj)
        props.filterFields &&
          props.filterFields.forEach(item => {
            query[item.name] = filterObj[item.name] ?? ""
          })
        if (props.parentId) {
          query[props.parentId] = props.parentValue
        }

        const requestPath = props.getRecordsPath
        const items: any = await API.postAction(requestPath, query)

        if (items.data.status === "ok") {
          console.log(items.data)
          setRecords(items.data.response)
          setTotalPages(items.data.quantityOfPages)
        }
      }

      // GET METHOD OR NOT SPECIFIED
      else {
        let query = `&page=${page}&quantity=${pageSize}`
        console.log(filterObj)
        props.filterFields &&
          props.filterFields.forEach(item => {
            query += `&${item.name}=${filterObj[item.name] ?? ""}`
          })
        if (props.parentId) {
          query[props.parentId] = props.parentValue
        }
        const requestPath = props.getRecordsPath.includes("?")
          ? props.getRecordsPath + query
          : props.getRecordsPath + "?" + query
        const items: any = await API.getAction(requestPath)

        if (items.data.status === "ok") {
          console.log(items.data)
          setRecords(items.data.response)
          setTotalPages(items.data.quantityOfPages)
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }
  const deleteRecord = async (item: any) => {
    if (item) {
      confirmAlert({
        closeOnClickOutside: false,
        message: t("Are you sure you want to delete this record?") ?? "",
        buttons: [
          {
            label: t("Yes"),
            onClick: async () => {
              await API.getAction(props.deleteRecordPath + "?id=" + item.id)
              await getRecords()
              setRecord(null)
            },
          },
          {
            label: "No",
            onClick: () => {
              console.log("no")
            },
          },
        ],
      })
    }
  }
  const addEditRecord = async () => {
    try {
      let query = formik.values

      //Now let's go over all the fields to check if there's a password
      fields.forEach((item: CRUDField) => {
        if (item.htmlType === "password") {
          if (query[item.name] && query[item.name].length > 0) {
            let password = Sha256.hash(query[item.name])
            query[item.name] = password
          }
        }
      })

      if (props.parentId) {
        query[props.parentId] = props.parentValue
      }

      console.log(query)
      setIsLoading(true)
      let request = (await API.postAction(
        props.addEditRecordPath,
        query
      )) as any
      if (!query?.id && props.childrens) {
        let response = request.response
        fillFormikObject(response)
      } else {
        toggle(null)
        if (!props.isModal) {
          setShowModal(false)
        }
        toast.success(t("record added"))
      }
    } catch (ex: any) {
      console.error(ex)

      toast.error(ex)
    } finally {
      setIsLoading(false)
    }
  }

  const toggle = async (item?: any) => {
    if (!item) {
      formik.resetForm()
    }
    setRecord(item)
    fillFormikObject(item)

    setShowAddEdit(!showAddEdit)
    if (showAddEdit) {
      await getRecords()
    }
  }
  const updateField = (fieldName: string, fieldValue: any) => {
    console.log(fieldName, fieldValue)
    formik.setFieldValue(fieldName, fieldValue)
  }
  // EVENTS

  const onPageChanged = (obj: number) => {
    setPage(obj)
  }

  const toggleFilter = () => setShowFilter(!showFilter)

  const toggleAddEdit = (item?: any | null) => {
    fillFormikObject(item)
    if (!props.isModal) {
      setShowModal(!showModal)
      setShowAddEdit(true)
    } else {
      setShowAddEdit(!showAddEdit)
    }
  }
  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }
  const toggleClose = () => {
    setShowModal(false)
    if (props.isModal) {
      setShowAddEdit(false)
    }
    formik.resetForm()
    fillFormikObject(null)

    console.log(props.toggleCRUD, props.isModal)
    if (props.toggleCRUD && props.isModal) {
      props.toggleCRUD()
    }
  }

  useEffect(() => {
    console.log("page", page, props.parentValue)
    getRecords()
  }, [page, props.parentValue])
  useEffect(() => {
    prepareFormikObject()
    //  getRecords()
    if (props.showModal) {
      setShowModal(true)
    }
  }, [])
  useEffect(() => {
    console.log(filterObj)
    if (filterObj && Object.keys(filterObj).length > 0) {
      console.log(filterObj)
      getRecords()
    }
  }, [filterObj])

  // UI
  const tableUI = (handleClose?: () => void) => {
    return (
      <>
        <div className="row m-0 p-0">
          <div className="col-12 p-0">
            <div className="table-responsive">
              <table className="table table-striped table-bordered w-100">
                <thead>
                  <tr>
                    {fieldsList &&
                      fieldsList.map((item, index) => {
                        return (
                          <th key={index} className={item.cssClass}>
                            {item.title}
                          </th>
                        )
                      })}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {records &&
                    records.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          {fieldsList &&
                            fieldsList.map((itemCol: any, indexCol: number) => {
                              let val = itemCol.alternativeTitle
                                ? itemCol.alternativeTitle
                                : itemCol.name
                              return (
                                <td key={indexCol} className={itemCol.cssClass}>
                                  {itemCol.htmlType ? (
                                    itemCol.htmlType === "bool" ? (
                                      item[val] ? (
                                        <i className="fa-solid fa-toggle-on text-primary"></i>
                                      ) : (
                                        <i className="fa-solid fa-toggle-off"></i>
                                      )
                                    ) : itemCol.htmlType === "color" ? (
                                      item[val] ? (
                                        <i
                                          className="fa-solid fa-circle"
                                          style={{ color: item[val] }}
                                        ></i>
                                      ) : (
                                        <i className="fa-solid fa-circle"></i>
                                      )
                                    ) : itemCol.htmlType === "date" ? (
                                      item[val] ? (
                                        moment(item[val]).format("MMM DD, YYYY")
                                      ) : (
                                        "-"
                                      )
                                    ) : itemCol.htmlType === "image" ? (
                                      item[val] && (
                                        <img
                                          alt=""
                                          loading="lazy"
                                          className=" img-thumbnail my-2"
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            objectFit: "cover",
                                          }}
                                          id={"image" + index}
                                          src={
                                            item[val].includes("base64")
                                              ? item[val]
                                              : item[val].includes("/")
                                              ? `${Settings.BasePath}/${item[val]}`
                                              : `${Settings.BasePath}/files/${item[val]}`
                                          }
                                        />
                                      )
                                    ) : (
                                      item[val]
                                    )
                                  ) : (
                                    item[val]
                                  )}
                                </td>
                              )
                            })}
                          <td className="text-end">
                            <div className="btn-group">
                              <button
                                className="btn btn-sm "
                                disabled={props.readOnly ?? false}
                                type="button"
                                onClick={() => toggleAddEdit(item)}
                              >
                                <i className="fa fa-edit" />
                              </button>
                              <button
                                className="btn btn-sm"
                                disabled={props.readOnly ?? false}
                                type="button"
                                onClick={() => {
                                  deleteRecord(item)
                                }}
                              >
                                <i className="fa fa-trash text-danger" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  {records && records.length === 0 && (
                    <tr>
                      <td
                        colSpan={fieldsList.length + 1}
                        className="text-center p-2"
                      >
                        {t("no_records")}
                      </td>
                    </tr>
                  )}
                </tbody>

                <tfoot></tfoot>
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }

  const RegularUI = () => {
    const UI = (
      <Container>
        <ActionBar
          headerStyle={props.headerStyle}
          title={props.title ?? ""}
          icon={props.icon ?? ""}
          actions={
            <>
              <button
                className="btn btn-primary btn-sm me-2"
                onClick={() => {
                  toggleAddEdit(null)
                }}
              >
                <i className="fa fa-plus " />
              </button>
              {props.filterFields && (
                <button
                  className={
                    "btn  btn-sm  " + (showFilter ? "btn-secondary" : "")
                  }
                  onClick={() => toggleFilter()}
                >
                  <i className="fa fa-filter" />
                </button>
              )}
            </>
          }
        ></ActionBar>
        {showFilter && <div className="my-4">{filterUI()}</div>}
        <Card className="my-4">
          <Card.Body className="p-0">{tableUI()}</Card.Body>
        </Card>
        <Card className="mb-4">
          <Card.Body className="p-0 pt-2">
            <Pagination
              totalPages={totalPages}
              currentPage={page}
              onPageChanged={onPageChanged}
            />
          </Card.Body>
        </Card>
      </Container>
    )
    return UI
  }

  const filterUI = () => {
    return (
      <Filter
        toggleFilter={toggleFilter}
        fields={props.filterFields}
        filterAction={() => {
          getRecords()
        }}
        setFilterFields={getFilterValues}
      />
    )
  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={toggleClose}
        size={props.modalSize ? props.modalSize : "lg"}
        className={props.cssClass}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
          {!showAddEdit && (
            <>
              <button
                disabled={props.readOnly ?? false}
                className="ms-2 btn btn-sm btn-primary text-white"
                type="button"
                onClick={() => toggleAddEdit(null)}
              >
                <i className="fa-solid fa-plus"></i>
              </button>
              {props.filterFields && (
                <button
                  className={
                    " ms-2 btn  btn-sm  " + (showFilter ? "btn-secondary" : "")
                  }
                  onClick={() => toggleFilter()}
                >
                  <i className="fa fa-filter" />
                </button>
              )}
              {isLoading && (
                <div
                  className="ms-2 spinner-grow spinner-grow-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          {showAddEdit ? (
            <div className="flex gap-2 flex-col px-4">
              <div className="row">
                {fields &&
                  fields
                    .filter(x => x.htmlType !== "hidden")
                    .map((item, index) => {
                      return (
                        <FieldSelector
                          key={index}
                          model={item}
                          updateField={updateField}
                          resultadosCedula={resultadosCedula}
                          resultadosRnc={resultadosRnc}
                          obj={formik.values}
                          value={
                            formik.values[
                              item.name as keyof typeof formik.values
                            ]
                          }
                          errors={formik.errors}
                        />
                      )
                    })}
              </div>
              {formik.values.id &&
              props.childrens &&
              props.childrens.length > 0 ? (
                <div className="grid gri-cols-1 gap-2	">
                  <h2 className="text-3xl font-bold dark:text-white">
                    {t("subSections")}
                  </h2>
                  <div className="tabs tabs-boxed ">
                    {props.childrens.map((item, index) => {
                      const parentId: string =
                        formik.values && formik.values["id"]
                      return (
                        <>
                          <input
                            type="radio"
                            name={item.id}
                            role="tab"
                            className="tab"
                            aria-label={item.name}
                            checked={index === 0}
                            id={item.id}
                            //onChange={() => {}}
                          />

                          <div
                            role="tabpanel"
                            className="tab-content  bg-slate-100"
                          >
                            {item.component({ parentId: parentId })}
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <>
              {showFilter && <div className="my-4">{filterUI()}</div>}

              {tableUI()}
              <Card className="mb-4">
                <Card.Body className="p-0 pt-2">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={page}
                    onPageChanged={onPageChanged}
                  />
                </Card.Body>
              </Card>
            </>
          )}
        </Modal.Body>
        {showAddEdit && (
          <Modal.Footer>
            {/* {formik.errors? <div className="text-red-600">{console.log(formik.errors)}</div> :null} */}
            <div className="modal-action m-0 p-2 ">
              {isLoading && (
                <div className="me-4 d-inline-block">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
              <button
                className="btn btn-secondary me-2"
                disabled={isLoading}
                onClick={() => setShowAddEdit(false)}
                type="button"
              >
                {t("Cancel")}
              </button>
              {/* if there is a button in form, it will close the modal */}
              <button
                className="btn btn-primary text-white"
                disabled={isLoading}
                onClick={() => formik.submitForm()}
                type="button"
              >
                {t("Save")}
              </button>
            </div>
          </Modal.Footer>
        )}
      </Modal>

      {!props.isModal && RegularUI()}
    </>
  )
}

export default CRUD
